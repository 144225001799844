import { BrowserRouter, Route, Routes, redirect } from "react-router-dom";
import "./App.css";
import React, { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { analytics, auth } from "./api/firebaseConfig";
import { lazy, Suspense, useCallback } from "react";
import {
  checkUserLanguagesAndRedirect,
  clearLocalLearningLanguage,
  getLearningLanguageCodeFromLocalStorage,
  getUserLanguageCodeFromLocalStorage,
  updateLanguages,
} from "./api/user-language.service";
import { UserProvider } from "./auth/user-context";
import { useTranslation } from "react-i18next";
import { getLanguageWithCode } from "./utils/languages";
import { RecoilRoot, useRecoilState } from "recoil";
import {
  isAuthenticationFinishedState,
  learningLanguageState,
  userLanguageState,
  userState,
} from "./state/user-state";

import { classroomIDState } from "./state/classroom-state";
import {
  hasAnyLocalData,
  hasLocalClassrooms,
  hasLocalSentences,
  hasLocalSnippets,
  printLocalData,
  printLocalStorage,
} from "./api/localStorage";
import { mergeDataState } from "./state/app-state";
import { logEvent_LoginDialog_LoginSuccess } from "./analytics/analytics";
import { setUserProperties } from "firebase/analytics";
import { getDatabase, ref, get } from "firebase/database";
import Story from "./stories/story";
import AddStory from "./stories/add-story";
import StoryList from "./stories/story-list";
import AdminManageStories from "./admin/manage-stories/admin-manage-stories";
import LandingPage from "./components/landing/LandingPage";
import ExampleClassroom from "./components/classrooms/example-classroom";
import ResetPassword from "./components/reset-password";
import ExampleStory from "./components/example-story";
import PageNotFound from "./components/page-not-found";
import ErrorBoundary from "./components/error-boundary";
import { Constants } from "./utils/constants";



const Documents = lazy(() => import("./documents/documents"));
const Terms = lazy(() => import("./terms/terms"));
const PrivacyPolicy = lazy(() => import("./privacy-policy/privacy-policy"));
const Sentences = lazy(() => import("./components/sentences"));
const Register = lazy(() => import("./components/register"));
const ChooseLanguage = lazy(() => import("./components/choose-language/chooseLanguage"));
const UserProfile = lazy(() => import("./components/profile"));
const ClassroomList = lazy(
  () => import("./components/classrooms/classroom-list"),
);
const Classroom = lazy(() => import("./components/classrooms/classroom"));
const AddSnippetModal = lazy(() => import("./dialogs/add-snippet-modal"));
const UpdateMissingDataModal = lazy(
  () => import("./dialogs/update-missing-data-modal"),
);
const MergeDataModal = lazy(() => import("./dialogs/merge-data-modal"));
const MemoizedLandingPage = React.memo(LandingPage);
const MemoizedResetPassword = React.memo(ResetPassword);
const MemoizedDocuments = React.memo(Documents);
const MemoizedTerms = React.memo(Terms);
const MemoizedPrivacyPolicy = React.memo(PrivacyPolicy);
const MemoizedSentences = React.memo(Sentences);
const MemoizedPageNotFound = React.memo(PageNotFound);
const MemoizedStory = React.memo(Story);
const MemoizedExampleStory = React.memo(ExampleStory);
const MemoizedAddStory = React.memo(AddStory);
const MemoizedStoriesList = React.memo(StoryList);
const MemoizedRegister = React.memo(Register);
const MemoizedChooseLanguage = React.memo(ChooseLanguage);
const MemoizedUserProfile = React.memo(UserProfile);
const MemoizedAdminManageStories = React.memo(AdminManageStories);
const MemoizedClassroomList = React.memo(ClassroomList);
const MemoizedClassroom = React.memo(Classroom);
const MemoizedExampleClassroom = React.memo(ExampleClassroom);
const MemoizedAddSnippetModal = React.memo(AddSnippetModal);
const MemoizedUpdateMissingDataModal = React.memo(UpdateMissingDataModal);
const MemoizedMergeDataModal = React.memo(MergeDataModal);

function App() {
  const [learningLanguage, setLearningLanguage] = useRecoilState(
    learningLanguageState,
  );
  const [userLanguage, setUserLanguage] = useRecoilState(userLanguageState);
  const [mergeState, setMergeState] = useRecoilState(mergeDataState);
  const [user, setUser] = useRecoilState(userState);
  const [initiallyLoggedIn, setInitiallyLoggedIn] = useState(null);
  const [authenticationFinished, setAuthenticationFinished] = useRecoilState(
    isAuthenticationFinishedState,
  );

  const { i18n } = useTranslation();
  
  useEffect(() => {
    
    printLocalStorage()

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      let snapshot;
      let userData;

      if (user) {
        const db = getDatabase();
        const userRef = ref(db, "users/" + user?.uid);
        snapshot = await get(userRef);
        userData = snapshot.exists() ? snapshot.val() : {};
      }
      setUser({
        uid: user?.uid,
        email: user?.email,
        displayName: user?.displayName,
        photoURL: user?.photoURL,
        providerId: user?.providerId,
        dateOfBirth: userData?.dateOfBirth,
      });

      setUserProperties(analytics, {
        userId: user?.uid,
        email: user?.email,
        provider: user?.providerId,
      });

      if (authenticationFinished && !initiallyLoggedIn && user) {
        return;
      }

      if (user) {
        // check if user logged in with google

        checkUserLanguagesAndRedirect(user.uid).then((languages) => {
          if (languages) {
            setUserLanguage(getLanguageWithCode(languages.userLanguage));
            setLearningLanguage(
              getLanguageWithCode(languages.learningLanguage),
            );
          } else {
            setUserLanguageFromLocalStorage();
          }

          if (initiallyLoggedIn == null) {
            setInitiallyLoggedIn(true);
          }

          setAuthenticationFinished(true);
        });
      } else {
        // user is not logged in
        if (initiallyLoggedIn == null) {
          setInitiallyLoggedIn(false);
        }
        setUser(null);
        setUserLanguageFromLocalStorage();
        setLearningLanguageFromLocalStorage();
        setAuthenticationFinished(true);
      }
    });
    return () => unsubscribe();
  }, [authenticationFinished]);

  useEffect(() => {
    if (user && authenticationFinished && initiallyLoggedIn === false) {
      handleLoginSuccess(user);
    }
  }, [user, initiallyLoggedIn, authenticationFinished]);

  const handleLoginSuccess = useCallback(
    (user) => {
      logEvent_LoginDialog_LoginSuccess();
      // clear

      clearLocalLearningLanguage()

      if (hasAnyLocalData()) {
        
        const data = {
          hasLocalSentences: hasLocalSentences(),
          hasLocalSnippets: hasLocalSnippets(),
          hasLocalClassrooms: hasLocalClassrooms(),
          isOpen: true,
        };
        setMergeState(data);
      } else {
        window.location.href = "/";
      }
    },
    [setMergeState],
  );

  const setUserLanguageFromLocalStorage = useCallback(() => {
    let localUserLanguageCode = getUserLanguageCodeFromLocalStorage();
    if (!localUserLanguageCode) {
      localUserLanguageCode = "en";
    }
    let userLanguage = getLanguageWithCode(localUserLanguageCode);
    setUserLanguage(userLanguage);
  }, [setUserLanguage]);

  const setLearningLanguageFromLocalStorage = useCallback(() => {
    let localLearningLanguageCode = getLearningLanguageCodeFromLocalStorage()
    console.log('local storage loaded code learning lang ' + localLearningLanguageCode);
    if (!localLearningLanguageCode) {
      return
    }
    let learningLanguage = getLanguageWithCode(localLearningLanguageCode);
    if (learningLanguage) {

      console.log('language from code ' + learningLanguage.code);
      setLearningLanguage(learningLanguage);
    }

  }, [setLearningLanguage]);

  async function getServerUserLanguage() {
    const db = getDatabase();
    const userRef = ref(db, "users/" + user?.uid);
    const snapshot = await get(userRef);
    const userData = snapshot.exists() ? snapshot.val() : {};
    return userData?.userLanguage;
  }

  async function getServerLearningLanguage() {
    const db = getDatabase();
    const userRef = ref(db, "users/" + user?.uid);
    const snapshot = await get(userRef);
    const userData = snapshot.exists() ? snapshot.val() : {};
    return userData?.learningLanguage;
  }

  useEffect(() => {
    console.log("useEffect userLanguage " + userLanguage);
    if (userLanguage) {
      console.log('i18n app.js userLanguage ' + userLanguage.code);
      i18n.changeLanguage(userLanguage.code);
    }
    if (user) {
      getServerUserLanguage().then((data) => {
        if (data) {
          updateLanguages(user?.uid, userLanguage, null);
        }
      });
    } else {
      if (userLanguage) {
        localStorage.setItem("userLanguage", userLanguage.code);
      }
    }
  }, [userLanguage, i18n, user?.uid]);

  useEffect(() => {
    if (learningLanguage) {
      if (user) {
        getServerLearningLanguage().then((data) => {
          if (data) {
            updateLanguages(user?.uid, null, learningLanguage);
          }
        });
      } else {
        updateLanguages(null, null, learningLanguage);
      }
    }
  }, [learningLanguage, user?.uid]);

  return (
    <div className="app" style={{ height: "100vh", width: "100%" }}>
      <UserProvider
        value={{
          user,
          userLanguage,
          setUserLanguage,
          authenticationFinished,
          isInitiallyLoggedIn: initiallyLoggedIn,
        }}
      >
        <ErrorBoundary>
          <BrowserRouter>
            <Suspense >
              <Routes>
                <Route path="/" element={<MemoizedLandingPage />} />
                <Route path="*" element={<MemoizedPageNotFound />} />

                {/* WRITING  */}
                <Route path="/documents" element={<MemoizedDocuments />} />
                <Route path="/document/:language/:id" element={<MemoizedSentences />} />
                <Route path="/example-document" element={<MemoizedSentences />} />

                {/* OTHER */}
                <Route path="/register" element={<MemoizedRegister />} />
                <Route path="/choose-language" element={<MemoizedChooseLanguage />} />

                {/* STORIES */}
                <Route path="/stories" element={<MemoizedStoriesList />} />
                <Route path="/add-story" element={<MemoizedAddStory />} />
                <Route path='/story/:language/:id' element={<MemoizedStory />} />
                <Route path='/example-story' element={<MemoizedExampleStory />} />

                {/* CLASSROOM  */}
                <Route path="/classrooms" element={<MemoizedClassroomList />} />
                <Route path="/classroom/:id" element={<MemoizedClassroom />} />
                <Route path="/profile" element={<MemoizedUserProfile />} />
                <Route path="/example-classroom" element={<MemoizedExampleClassroom />} />
                {/* ADMIN */}
                
                { Constants.isLocalhost && <Route path="/admin/manage-stories" element={<MemoizedAdminManageStories />} /> }

                {/* AUTH & USER */}
                <Route path="/reset-password" element={<MemoizedResetPassword />} />

                {/* LEGAL */}
                <Route path="/terms" element={<MemoizedTerms />} />
                <Route path="/privacy-policy" element={<MemoizedPrivacyPolicy />} />

              </Routes>
            </Suspense>
            <MemoizedMergeDataModal />
          </BrowserRouter>
        </ErrorBoundary>
        <Suspense fallback={<div></div>}>
          <MemoizedAddSnippetModal />
          <MemoizedUpdateMissingDataModal />
        </Suspense>
      </UserProvider>
    </div>
  );
}


export default App;

// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import i18next from 'i18next';

i18n
  .use(HttpBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    lng: 'en', // Default language
    backend: {
      loadPath: '/locales/snippets/{{lng}}.json', // Path to your translation files
    },
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false, // React already does escaping
    },
  });

  export async function localizeInflection(inflection, language = 'en') {
    console.log('Localizing inflection ', JSON.stringify(inflection));
    const currentLanguage = i18next.language
    await i18next.changeLanguage(language);
    
    let toReturn = '';
    if(inflection.cases) {
      // noun (russian)
      const cases = inflection.cases.map((c) => i18next.t(`lang.case_${c}`).toLowerCase()).join(', ')
      const noun = inflection.noun;

      const inflectionNumber = inflection.number || 'singular' // default to singular
      const number = i18next.t(`lang.${inflectionNumber}`)
      
      toReturn = i18next.t('inflection.template_noun_number_cases', { number, cases, noun });
    } else if(inflection.person && inflection.number !== undefined && inflection.number !== 0) {
      const person = i18next.t(`inflection.person.${inflection.person}`);
      const number = i18next.t(`inflection.number.${inflection.number}`);
      const tense = i18next.t(`inflection.tense.${inflection.tense}`);
      const verb = inflection.verb;
      toReturn = i18next.t('inflection.template', { person, number, tense, verb });
    } else {
      const tense = i18next.t(`inflection.tense.${inflection.tense}`);
      const verb = inflection.verb;
      toReturn = i18next.t('inflection.tense_only_template', { tense, verb });
    }

    await i18next.changeLanguage(currentLanguage);
    console.log('Returning inflection ' + toReturn);
    return toReturn;
}

import { Box, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";

export function FlagView({ title, color }) {
  const [flagTitle, setFlagTitle] = useState(title);
  const [flagColor, setFlagColor] = useState(color);

  useEffect(() => {
      setFlagTitle(title);
      setFlagColor(color);
  }, [title, color]);

  return (
      <Box opacity={0.6} backgroundColor={flagColor} borderRadius={4} color='white'>
          <Text>{flagTitle}</Text>
      </Box>
  );
}

import { ClassroomLayoutComponentType, ClassroomSnippetsContentType } from "../api/classroom.service";

export const Constants = {
  isLocalhost: window.location.hostname === "localhost",
  PAGE_TITLE_APP_NAME: "Learner.app",
  MEDIA_QUERY_MOBILE: "(max-width: 768px)",
  PASSWORD_MIN_LENGTH: 8,
  DISPLAY_NAME_MIN_LENGTH: 2,
  MAX_EXAMPLES: 2,
  PAGE_SIZE: 100,
  WRITING_MAX_SENTENCE_LENGTH: 300,
  SORT_OPTION_ALPHABETICALLY: "alphabetical",
  SORT_OPTION_BY_DATE: "date",
  SORT_OPTION_DEFAULT: "alphabetical",
  SORT_ORDER_ASC: "asc",
  SORT_ORDER_DESC: "desc",
  SORT_ORDER_DEFAULT: "asc",
  NOTES_TYPING_DELAY_MS: 500,
  LocalStorage: {
    SNIPPETS_SORT_ORDER: "snippetsSortOrder", // ASC DESC
    SNIPPETS_SORT_OPTION: "snippetsSortOption", // alphabetical / date
    SNIPPETS_SORT_ORDER_DYNAMIC: (index) => `snippetsSortOrderDynamic_${index}`, // ASC DESC
    SNIPPETS_SORT_OPTION_DYNAMIC: (index) =>
      `snippetsSortOptionDynamic_${index}`, // alphabetical / date

    ANONYMOUS_USER_CLASSROOM_LAYOUT_TEACHER:
      "anonymousUserClassroomLayoutTeacher",
    ANONYMOUS_USER_CLASSROOM_LAYOUT_STUDENT:
      "anonymousUserClassroomLayoutStudent",

    CLASSROOM_SESSIONS_DICT: "classroomSessionsDict",
  },
  DynamicLayout: {
    DEFAULT_STUDENT_LAYOUT: {
      layout: "211",
      components: {
        0: {
          type: ClassroomLayoutComponentType.TRANSLATE_WORD,
        },
        1: {
          type: ClassroomLayoutComponentType.SNIPPETS,
          filter: ClassroomSnippetsContentType.CLASSROOM,
        },
        2: {
          type: ClassroomLayoutComponentType.NOTES,
        },
        3: {
          type: ClassroomLayoutComponentType.SNIPPETS,
          filter: ClassroomSnippetsContentType.ALL,
        },
      },
    },
    DEFAULT_TEACHER_LAYOUT: {
      layout: "112",
      components: {
        0: {
          type: ClassroomLayoutComponentType.SNIPPETS,
          filter: ClassroomSnippetsContentType.CLASSROOM,
        },
        1: {
          type: ClassroomLayoutComponentType.NOTES,
        },
        2: {
          type: ClassroomLayoutComponentType.TRANSLATE_WORD,
        },
        3: {
          type: ClassroomLayoutComponentType.DICTIONARY_SEARCH,
        },
      },
    },
  },
  zIndex: {
    header: 50,
    popover: 900,
    toast: 1000
  },
  DatabasePath: {
    DICTIONARY_CACHE: "dictionary_cache",
    DICTIONARY_CACHE_ENGLISH: "dictionary_cache_en",
    DICTIONARY_CACHE_TRANSLATION: "dictionary_cache_translation",
    USER_SNIPPETS: "user_snippets"
  },
  defaultBucketURL: `https://storage.googleapis.com/languagelearning-310010.appspot.com/`,
  storageURLWithPath: function (path) {
    return this.defaultBucketURL + path
  },
  Landing: {
    SECTION_TITLE_HEADER_SIZE: "xl",
    SECTION_TITLE_SIZE: "4xl",
    SECTION_TITLE_TOP_MARGIN: "1",
    SECTION_TITLE_BOTTOM_MARGIN: "3",
  },
  Header: {
    nonAuthedLinks: [
      { key: "documents.writing", url: "/example-document" },
      { key: "stories.page_title", url: "/example-story" },
      { key: "classrooms.header_title", url: "/example-classroom" },
    ],
    authedLinks: function () {
      let links = [
        { key: "documents.writing", url: "/documents", activePaths: ['/documents', '/document/{lang}{id}'] },
        { key: "stories.page_title", url: "/stories", activePaths: ['/stories', '/story/{lang}/{id}'] },
        { key: "classrooms.header_title", url: "/classrooms", activePaths: ['/classrooms', '/classroom/{id}'] },
      ]
      if (Constants.isLocalhost) {
        links.push({ key: "Manage Stories", url: "/admin/manage-stories" })
      }

      return links
    },
  },
  ExampleStory: {
    "es": {
      id: "in-the-park-L6Z2",
      languageCode: "es-MX"
    },
    "en": {
      id: "at-the-park-1k6s",
      languageCode: "en"
    },
    "ru": {
      id: "in-the-park-12NE",
      languageCode: "ru"
    }
  },
  Tutorial: {
    Key: {
      ExampleClassroom: "example-classroom",
      ExampleStory: "example-story",
      ExampleDocument: "example-document",
      ClassroomShareInfo: "classroom-share-info"
    }
  }
};

import { Box, Button, Center, Checkbox, Flex } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import SnippetTable from "../snippets/snippet-table";
import { useRecoilState } from "recoil";
import { learningLanguageState, userLanguageState } from "../state/user-state";
import { useTranslation } from "react-i18next";
import { classroomIDState, classroomRoleState } from "../state/classroom-state";
import { AddSnippetModalType } from "../dialogs/add-snippet-modal";
import { ClassroomRoleEnum } from "./classrooms/classroom-role";
import { showAddEditSnippetModal, showSnippetsModalState } from "../state/snippets-state";
import { set } from "@firebase/database";
import { copyJSONObject, isDefined } from "../utils/utils";
import { convertDefinitionsDictionaryToArray } from "../utils/snippet-wiktionary-parsing";
import { getVisibleSectionsFromSnippet } from "../utils/snippet-utils";
import { AddEditSnippetModalType } from "../snippets/snippet-modal";

export const DictionarySearchSelection = {
  SINGLE: 'SINGLE',
  MULTIPLE: 'MULTIPLE'
}

function DictionarySearchResultItem({ index, selected, selectionMode, onSelectionChanged, isSelectable, showCreateSnippet, onCreate, component }) {
  
  const [isSelected, setIsSelected] = useState(selected);

  const { t } = useTranslation();

  useEffect(() => {
    setIsSelected(selected);
  }, [selected]);

  const handleCheckboxChange = (event) => {
    const newChecked = event.target.checked;
    // Only call onSelectionChanged if the new state differs from the current `isSelected`
    if (newChecked !== isSelected) {
      setIsSelected(newChecked);
      onSelectionChanged?.(index, newChecked, selectionMode);
    }
  };

  return (
    <Box w='100%' h='100%'>
      
    <Flex
    w="100%"
    h="100%"
    borderWidth={isSelected ? 2 : 0}
    borderRadius={8}
    borderColor="green.500"
    p={1}
    position="relative"
    id={`searchResultItem_${index}`}
  >
    {selectionMode && (!isDefined(isSelectable) || isSelectable === true) && (
      <Box
        position="sticky"
        top="0"
        bottom="0"
        margin="auto"
        zIndex={1}
        height="fit-content"
      >
        <Checkbox size="lg" colorScheme='green' isChecked={isSelected} onChange={handleCheckboxChange}></Checkbox>
      </Box>
    )}
    <Box w="100%">{component}</Box>
    {showCreateSnippet && (
      <Center>
        <Button
          pl={0}
          pr={0}
          colorScheme="green"
          onClick={() => {
            onCreate?.();
          }}
        >
          {t("sentences.create_snippet")}
        </Button>
      </Center>
    )}
  </Flex>
  </Box>
  );
}


function DictionarySearchResults({ results, classroomID, customLearningLanguage, editMode, selectionMode, isSnippetSelectable, showEditHeaders, selectedItemID, showsCreateButton, highlightedText, hideCollapseSnippet, onSelectionChanged, onLinkClick, onEditSnippet, canPinSnippet, onDeleteSnippet}) {
  const [learningLanguage, setLearningLanguage] = useRecoilState(learningLanguageState)
  const [snippetTableLanguage, setSnippetTableLanguage] = useState(null)
  const [userLanguage, setUserLanguage] = useState(userLanguageState);
  const [selectedIndices, setSelectedIndices] = useState(new Map());
  const [headerEditModels, setHeaderEditModels] = useState([]);
  const [classroomRole, setClassroomRole] = useRecoilState(classroomRoleState);

  const [isShowingSnippetsModal, setShowingSnippetsModal] = useRecoilState(
    showSnippetsModalState
  );

  useEffect(() => {
    if (customLearningLanguage) {
      setSnippetTableLanguage(customLearningLanguage);
    } else {
      setSnippetTableLanguage(learningLanguage);
    }
  }, [customLearningLanguage, learningLanguage]);

  function createSnippetFromSearchTerm(snippetModel) {
    if (!snippetModel) {
      return;
    }

    let modelCopy = JSON.parse(JSON.stringify(snippetModel, null, 2));

    if (!snippetModel.id) {
      console.log("Can't create a snippet, missing an ID.")
      return;
    }

    // if snippet has no items, remove collapsable so the chevron is not shown
    if ((modelCopy.items?.length ?? 0) === 0) {
      delete modelCopy?.header?.collapsable;
      delete modelCopy?.header?.collapsesSnippet;
    }

    let id = null;
    if (classroomID && classroomRole === ClassroomRoleEnum.TEACHER) {
      id = classroomID;
    }

    showAddEditSnippetModal(
      setShowingSnippetsModal,
      null,
      modelCopy?.pinned_at,
      null,
      id,
      results,
      snippetModel.id,
      null,
      null,
      modelCopy?.term, // this should be the search term, not the term from the selected snippet
      modelCopy?.definitionsLanguageCode,
      AddEditSnippetModalType.CLASSROOM_FALLBACK_TO_USER(id)
    );
  }

  useEffect(() => {
    let headerEditModels = []

    if (showEditHeaders && results && Array.isArray(results)) {
      for (let i = 0; i < results.length; i++) {
        headerEditModels.push(headerEditModelFor(results[i]))
      }
    }

    setHeaderEditModels(headerEditModels);
  }, [results, showEditHeaders]);

  useEffect(() => {
    const selectedIndicesMap = new Map();

    if (selectedItemID) {
      results.forEach((result, index) => {
        if (result.id === selectedItemID) {
          selectedIndicesMap.set(index, true);
        }
      });
    }

    console.log(`results and selectedItemID changed, setting selectedIndices ${JSON.stringify(selectedIndicesMap)} for selectedItemID ${selectedItemID} and results ${JSON.stringify(results)}`);
    setSelectedIndices((prev) => {
      return selectedIndicesMap
    });
  }, [results, selectedItemID]);

  function notifyIndicesChanged(indicesMap) {
    const indices = Array.from(indicesMap.keys());
    const models = indices.map(index => headerEditModels[index]);
    console.log(`Calling onSelectionChanged with models: ${JSON.stringify(models)} and indices: ${indices}`);
    onSelectionChanged?.(models, indices);
  }

  useEffect(() => {
    console.log("selectedIndices changed ", selectedIndices);
    if (selectedIndices.size > 0) {
      notifyIndicesChanged(selectedIndices);
    }
  }, [selectedIndices]);

  // useEffect(() => {
  //   if (selectedIndices == null) {
  //     return;
  //   }
  //   console.log('selectedIndices changed', selectedIndices)
  //   notifyIndicesChanged(selectedIndices)
  // }, [selectedIndices]);

  function headerEditModelFor(model) {
    // "selectedDefinition":{"customTranslation":"def tr","id":"668d2f59cWqrhvDa","languageCode":"en"} 

    let definitions = null

    if(model.definitions) {
      definitions = convertDefinitionsDictionaryToArray(copyJSONObject(model.definitions))
    }

    let headerModel = {
      term: model.term ?? model.plainTextTerm,
      definitions: definitions,
      selectedDefinitionID: model.selectedDefinition?.id,
      customDefinitionText: model.customDefinition,
      collapsesSnippet: model.collapsesSnippet,
      visibleSections: getVisibleSectionsFromSnippet(model)
    }

    return headerModel
  }

  return (
    <Box id='searchResultsBox' w='100%' h='100%' data-test='search-results' >
      {results && Array.isArray(results) && <Box>

        {selectedIndices !== null && results.length > 0 && results.map((result, index) => (
          <DictionarySearchResultItem
            data-test={`search-result-item-${index}`}
            snippetModel={result}
            component={<SnippetTable
              data-test={`search-result-snippet-${index}`}
              key={index}
              editMode={editMode}
              w='100%'
              h='100%'
              id={`searchTerm_${index}`}
              showSelectedTextOnlyOnExactMatch={true}
              includeNonHighlightedSections={true}
              selectedText={highlightedText}
              editHeaderModel={headerEditModels[index]}
              hideCollapseSnippet={isDefined(hideCollapseSnippet) ? hideCollapseSnippet : true}
              savedVisibleSections={result.selectedSections}
              showMoreButton={isDefined(onEditSnippet) || isDefined(onDeleteSnippet) || canPinSnippet}
              languageCode={snippetTableLanguage?.code}
              userLanguageCode={userLanguage?.code}
              minCellHeight={0}
              cellModel={result}
              displayAllSections={true}
              onDeleteSnippet={onDeleteSnippet}
              onEditSnippet={onEditSnippet}
              isInitiallyExpanded={!showsCreateButton}
              canEditSnippet={isDefined(onEditSnippet)}
              canDeleteSnippet={isDefined(onDeleteSnippet)}
              canPinSnippet={(isDefined(classroomID) ? false : (isDefined(canPinSnippet) ? canPinSnippet : true))}
              onEditHeaderChanged={(editModel) => {
                if(selectedItemID) {
                  notifyIndicesChanged(selectedIndices);
                }
                
              }}
              onLinkClick={onLinkClick}
              onEditSectionsChanged={(sections) => {
                if(headerEditModels && headerEditModels[index]) {
                  headerEditModels[index].visibleSections = JSON.parse(JSON.stringify(sections ?? {}))
                }
                notifyIndicesChanged(selectedIndices);
              }}
            />}
            selectionMode={selectionMode}
            isSelectable={ !isDefined(isSnippetSelectable) || isSnippetSelectable(result) }
            showCreateSnippet={showsCreateButton && !result.inflectionEntry}
            index={index}
            selected={selectedIndices.get(index) === true}
            onSelectionChanged={(index, selected, selectionMode) => {
              if (!selectionMode) {
                return;
              }
              console.log('onSelectionChanged, prev selectedIndices', selectedIndices);
              setSelectedIndices(prev => {
                // Create a new Map from the previous state
                const newSelectedIndices = new Map(prev);

                if (selectionMode === DictionarySearchSelection.SINGLE) {
                  // Clear all previous selections if in SINGLE selection mode
                  newSelectedIndices.clear();
                  if (selected) {
                    newSelectedIndices.set(index, true);
                  }
                } else {
                  // For MULTIPLE selection mode, toggle the selected state
                  if (selected) {
                    newSelectedIndices.set(index, true);
                  } else {
                    newSelectedIndices.delete(index);
                  }
                }

                console.log('selection changed', index, selected, selectionMode);
                return newSelectedIndices; // Return the new state
              });
            }}
            onCreate={() => {
              createSnippetFromSearchTerm(result);
            }}
          />
        ))}
      </Box>}
    </Box>
  );
}

export default DictionarySearchResults;

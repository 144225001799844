const { Image } = require("@chakra-ui/react");

const RetinaImage = ({ src, alt, ...props }) => {
  const srcSet = `${src} 1x, ${src.replace(/(\.\w+)$/, "@2x$1")} 2x, ${src.replace(
    /(\.\w+)$/,
    "@3x$1"
  )} 3x`;

  return (
    <Image
      draggable="false"
      style={{ userSelect: "none" }}
      src={src}
      srcSet={srcSet}
      alt={alt}
      {...props}
    />
  );
};

export default RetinaImage;

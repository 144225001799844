import {
  Box,
  Flex,
  Icon,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Text,
  VStack,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import { Constants } from "../utils/constants";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  CheckIcon,
  CloseIcon,
  PlusSquareIcon,
} from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
import { copyJSONObject, getElementID, isDefined, randomID, validateEmail } from "../utils/utils";
import SnippetModal, { AddEditSnippetModalType } from "../snippets/snippet-modal";
import SnippetTable from "../snippets/snippet-table";
import {
  addSnippet,
  deleteSnippet,
  pinSnippet,
  unpinSnippet,
  loadSnippets,
} from "../api/sentences.service";
import DeleteConfirmation from "../dialogs/delete-confirmation";
import {
  selector,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import {
  isAuthenticationFinishedState,
  learningLanguageState,
  userLanguageState,
  userState,
} from "../state/user-state";
import {
  addingSnippetKeysState,
  dynamicLayoutComponentDataState,
  filterSnippetsTextState,
  localUserSnippetsState,
  showAddEditSnippetModal,
  showDefaultAddSnippetModal,
  showSnippetsModalState,
  snippetsSortOptionDynamicStateForIndex,
  snippetsSortOptionState,
  snippetsSortOrderDynamicStateForIndex,
  snippetsSortOrderState,
  snippetsState,
} from "../state/snippets-state";

import {
  classroomIDState,
  classroomLanguageState,
  classroomRoleState,
} from "../state/classroom-state";
import {
  deleteClassroomSnippet,
  validateLayoutComponent,
} from "../api/classroom.service";
import { ClassroomRoleEnum } from "./classrooms/classroom-role";
import { IoSearch } from "react-icons/io5";
import MobileSnippetsSearch from "./mobile-snippets-search";
import { exampleClassroomSnippetAddedEvent, exampleClassroomState } from "./classrooms/example-classroom-state";

function ColumnSnippets({ position, exampleUserID }) {
  const [isMobile] = useMediaQuery(Constants.MEDIA_QUERY_MOBILE);

  const sortOrderDynamic = isDefined(position)
    ? snippetsSortOrderDynamicStateForIndex(position)
    : snippetsSortOrderState;
  const sortOptionDynamic = isDefined(position)
    ? snippetsSortOptionDynamicStateForIndex(position)
    : snippetsSortOptionState;

  // const [localSnippets, setLocalSnippets] = useRecoilState(
  //   localUserSnippetsState
  // );

  const [exampleClassroomSnippetAdded, setExampleClassroomSnippetAdded] = useRecoilState(exampleClassroomSnippetAddedEvent)

  const [userSnippets, setUserSnippets] = useRecoilState(
    snippetsState
  );

  const [mobileSearchMode, setMobileSearchMode] = useState(false);
  const [showMobileSearchID, setShowMobileSearchID] = useState("");

  // Sorting
  const [sortOption, setSortOption] = useRecoilState(sortOptionDynamic);
  const [sortOrder, setSortOrder] = useRecoilState(sortOrderDynamic);
  const [classroomRole, setClassroomRole] = useRecoilState(classroomRoleState);
  const [dynamicPosition, setDynamicPosition] = useState(position);

  const [customTitleKey, setCustomTitleKey] = useState(null);

  // Classroom
  // holds a classroom ID if the snippets are for a classroom
  const [classroomSnippetsID, setClassroomSnippetsID] = useState(null);
  const [addingSnippetKeys, setAddingSnippetKeys] = useRecoilState(
    addingSnippetKeysState
  );
  const [classroomLanguage, setClassroomLanguage] = useRecoilState(
    classroomLanguageState
  );

  // controls
  const [canEditSnippet, setCanEditSnippet] = useState(false);
  const [canDeleteSnippet, setCanDeleteSnippet] = useState(false);
  const [canPinSnippet, setCanPinSnippet] = useState(false);

  // Snippets raw data
  const [snippetModels, setSnippetModels] = useRecoilState(snippetsState);
  const [userSnippetKeys, setUserSnippetKeys] = useState({});

  const [dynamicLayoutComponentData, setDynamicLayoutComponentDataState] =
    useRecoilState(dynamicLayoutComponentDataState);

  const [snippetModalState, setShowingSnippetsModal] = useRecoilState(
    showSnippetsModalState
  );

  const [canAddSnippetItem, setCanAddSnippetItem] = useState(false);

  // Filter
  const [filterSnippetsText, setFilterSnippetsText] = useState(""); // text in the search bar
  const [localSnippetsTextValue, setLocalSnippetsTextValue] = useState(""); // term for which we filter the snippets; This value is set from filterSnippetsText with a delay
  const [filterSnippetsTextValue, setFilterSnippetsTextValue] = useRecoilState(
    filterSnippetsTextState
  ); // term for which we filter the snippets; This value is set from filterSnippetsText with a delay
  const [typingTimeoutId, setTypingTimeoutId] = useState(null);
  const filterSnippetsRef = useRef(null);

  // Delete
  const [deletingSnippetID, setDeletingSnippetID] = useState(null);
  const [isDeletingSnippet, setIsDeletingSnippet] = useState(false);

  // User and Languages
  const [learningLanguage, setLearningLanguage] = useRecoilState(
    learningLanguageState
  );
  const [userLanguage, setUserLanguage] = useRecoilState(userLanguageState);
  const [user, setUser] = useRecoilState(userState);
  const [isAuthenticationFinished, setAuthenticationFinished] = useRecoilState(
    isAuthenticationFinishedState
  );

  const [exampleClassroomData, setExampleClassroomData] = useRecoilState(exampleClassroomState);
  const [exampleClassroomUserID, setExampleClassroomUserID] = useState(null);

  const toast = useToast();

  const { t } = useTranslation();

  const filteredAndSortedSnippetsSelector = useMemo(
    () =>
      selector({
        key: `filteredAndSortedSnippets_${randomID()}`,
        get: ({ get }) => {
          const userID = exampleUserID || user?.uid || 'unauthed'

          let positionIndex = position;

          let sortOptionLocal = null;
          let sortOrderLocal = null;
          let snippetModels = null;
          let filterText = null;
          let canEditSnippets = true;

          let layoutData = get(dynamicLayoutComponentDataState); 

          const hasPositionData = isDefined(positionIndex) && isDefined(layoutData?.[userID]?.[positionIndex]);

          if (hasPositionData) {
            let data = layoutData?.[userID]?.[positionIndex];

            let role = null
            if (exampleUserID && exampleClassroomData) {
              // example
              role = exampleClassroomData?.permissions?.[userID]?.role
            } else {
              // regular
              role = classroomRole
            }

            if (!role) {
              return null
            }

            if (!data) {
              return null;
            }

            sortOptionLocal = sortOption || get(snippetsSortOptionDynamicStateForIndex(positionIndex));
            sortOrderLocal = sortOrder || get(snippetsSortOrderDynamicStateForIndex(positionIndex));

            // if(exampleClassroomData && exampleClassroomUserID) {

            // }

            snippetModels = data.snippets;
            canEditSnippets = data.canEditSnippets;
            filterText = ((data.filterText ?? localSnippetsTextValue) ?? '').toLowerCase().trim();
            let titleKey = data.titleKey;
            if (titleKey) {
              setCustomTitleKey(titleKey);
            } else {
              setCustomTitleKey(null);
            }

            if (data.classroomSnippets) {
              let classroomID = get(classroomIDState);
              setClassroomSnippetsID(classroomID);
            } else {
              setClassroomSnippetsID(null);
            }

            let isStudent = (role === ClassroomRoleEnum.STUDENT)
            let canAdd =
              isDefined(data.classroomSnippets) &&
              data.classroomSnippets &&
              isStudent;
            
            setCanAddSnippetItem(canAdd);
            // ************************************************************************************************
          } else {
            
            sortOptionLocal = sortOption || get(snippetsSortOptionState);
            sortOrderLocal = sortOrder || get(snippetsSortOrderState);
            snippetModels = get(snippetsState);
            filterText = (localSnippetsTextValue ?? "").toLowerCase().trim();
          }

          if (!snippetModels) {
            
            return null;
          }

          var filteredSnippets = [];
          if (snippetModels) {
            filteredSnippets = [...snippetModels];
          }

          if (filterText.length > 0) {
            // Filter snippets based on filterText
            filteredSnippets = filteredSnippets.filter((snippet) => {
              const filterTextItems = snippet.filterTextItems ?? [];
              const filterHeaderItems = snippet.filterHeaderItems ?? [];

              for (let textItem of filterTextItems) {
                if (textItem.startsWith(filterText)) {        
                  return true;
                }
              }

              for (let headerItem of filterHeaderItems) {
                if (headerItem.includes(filterText)) {
                  return true;
                }
              }

              return false;
            });
          }

          // Separate pinned and unpinned snippets
          const pinnedSnippets = filteredSnippets.filter(
            (snippet) => snippet.pinned_at
          );
          const unpinnedSnippets = filteredSnippets.filter(
            (snippet) => !snippet.pinned_at
          );

          // Sort pinned snippets
          pinnedSnippets.sort((a, b) => {
            return b.pinned_at - a.pinned_at;
          });

          // Sort unpinned snippets
          unpinnedSnippets.sort((a, b) => {
            let sort = 0;
            if (sortOptionLocal === Constants.SORT_OPTION_BY_DATE) {
              sort = b.createdAt - a.createdAt;
            } else {
              let aText = a.plainTextTerm ?? a.term
              let bText = b.plainTextTerm ?? b.term
              sort = aText?.localeCompare(bText);
            }

            if (sortOrderLocal === Constants.SORT_ORDER_DESC) {
              sort = -sort;
            }
            return sort;
          });

          // Combine pinned and unpinned snippets
          const sortedSnippets = [...pinnedSnippets, ...unpinnedSnippets];

          setCanEditSnippet(canEditSnippets);
          setCanPinSnippet(canEditSnippets);
          setCanDeleteSnippet(canEditSnippets);

          return sortedSnippets;
        },
      }),
    [position, classroomRole, localSnippetsTextValue, sortOption, sortOrder, exampleClassroomData, exampleClassroomUserID]
  );

  useEffect(() => {
    setExampleClassroomUserID(exampleUserID);
  }, [exampleUserID]);

  const [sortedAndFilteredSnippets, setSortedAndFilteredSnippets] =
    useRecoilState(filteredAndSortedSnippetsSelector);

  useEffect(() => {
    let keys = {};
    for (let key in snippetModels) {
      let snippet = snippetModels[key];
      if (snippet?.id) {
        keys[snippet.id] = true;
      }
    }
    setUserSnippetKeys(keys);
  }, [snippetModels]);

  useEffect(() => {
    if (mobileSearchMode) {
      setShowMobileSearchID(randomID());
    }
  }, [mobileSearchMode]);
  useEffect(() => {
    setDynamicPosition(position);
  }, [position]);

  // useEffect(() => {
  //   console.log(`sort order ${sortOrder} sort option ${sortOption}`);
  // }, [sortOrder, sortOption]);

  useEffect(() => {
    if (typingTimeoutId) {
      clearTimeout(typingTimeoutId); // Clear previous timeout
    }

    const newTimeoutId = setTimeout(() => {
      if (position) {
        if (dynamicLayoutComponentData?.[position]) {
          let state = { ...dynamicLayoutComponentData[position] };
          if (state) {
            state.filterText = filterSnippetsText;
          }

          setDynamicLayoutComponentDataState((prev) => ({
            ...prev,
            [position]: state,
          }));
        }
      } else {
        setFilterSnippetsTextValue(filterSnippetsText);
      }
      setLocalSnippetsTextValue(filterSnippetsText);
    }, 400);

    setTypingTimeoutId(newTimeoutId); // Set new timeout ID
  }, [filterSnippetsText]);

  useEffect(() => {
    if (!isAuthenticationFinished) {
      return;
    }
  }, [isAuthenticationFinished]);

  function onEditSnippetIDChanged(editingSnippetID) {
    if (editingSnippetID) {
      
      let snippetModel = sortedAndFilteredSnippets.find(
        (snippet) => snippet?.id === editingSnippetID
      );
      if (snippetModel) {
        let searchModels = []
        if (!snippetModel.plainTextTerm && !snippetModel.plainTextTranslation) {
          searchModels = [snippetModel]
        }
        showAddEditSnippetModal(
          setShowingSnippetsModal,
          editingSnippetID,
          snippetModel.pinned_at,
          t("sentences.edit_snippet_title"),
          classroomSnippetsID,
          searchModels,
          snippetModel.id,
          snippetModel.plainTextTerm,
          snippetModel.plainTextTranslation,
          snippetModel?.term,
          snippetModel?.definitionsLanguageCode,
          getSnippetModalType()
        )
      }
    } else {
      let state = { ...snippetModalState };
      state.editSnippetID = null;
      setShowingSnippetsModal(state);
    }
  }

  async function togglePinSnippet(id, isPinned) {
    if (isPinned) {
      await unpinSnippet(id, user?.uid, learningLanguage.code);
      setSnippetModels((prev) => {
        return [
          ...prev.filter((snippet) => snippet.id !== id),
          {
            ...prev.find((snippet) => snippet.id === id),
            pinned_at: null,
          },
        ];
      });
    } else {
      await pinSnippet(id, user?.uid, learningLanguage.code);
      setSnippetModels((prev) => {
        return [
          ...prev.filter((snippet) => snippet.id !== id),
          {
            ...prev.find((snippet) => snippet.id === id),
            pinned_at: new Date().getTime(),
          },
        ];
      });
    }
  }

  function handleSortSelect(sort) {
    if (sort === sortOption) {
      const newSortOrder =
        sortOrder === Constants.SORT_ORDER_ASC
          ? Constants.SORT_ORDER_DESC
          : Constants.SORT_ORDER_ASC;
      setSortOrder(newSortOrder);
    } else {
      setSortOption(sort);
      setSortOrder(Constants.SORT_ORDER_ASC);
    }
  }

  const clearFilterSnippetsAndFocus = () => {
    setFilterSnippetsText("");
    filterSnippetsRef.current?.focus();
  };

  function renderSortOrderIcon() {
    if (sortOrder === Constants.SORT_ORDER_ASC) {
      return <ArrowDownIcon ml={2} height="100%" width="24px" color="white" />;
    } else {
      return <ArrowUpIcon ml={2} height="100%" width="24px" color="white" />;
    }
  }

  function onClickAddSnippet() {
    let type = getSnippetModalType()
    showDefaultAddSnippetModal(setShowingSnippetsModal, t, t("sentences.add_snippet_title"), classroomSnippetsID, type)
  }

  function getSnippetModalType() {
    // let type = null
    if (exampleUserID && exampleClassroomData) {
      let role = exampleClassroomData?.permissions?.[exampleUserID]?.role
      let isStudent = (role === ClassroomRoleEnum.STUDENT)
      if (isStudent) {
        return AddEditSnippetModalType.USER_SNIPPET()
      } else {
        return AddEditSnippetModalType.EXAMPLE_CLASSROOM(learningLanguage?.code)
      }
    } else {
      return AddEditSnippetModalType.CLASSROOM_FALLBACK_TO_USER(classroomSnippetsID)
    }
  }

  async function reloadLocalSnippets() {
    // if (!user && learningLanguage?.code) {
    //   let snippets = await loadSnippets(null, learningLanguage.code, userLanguage.code);
    //   setLocalSnippets(snippets);
    // }
  }

  async function onClickAddToMySnippets(model) {
    if (!model || !model?.id) {
      return Promise.resolve();
    }

    try {

      setAddingSnippetKeys((prev) => ({
        ...prev,
        [model.id]: true,
      }));

      // FIXME: not sure why link isn't preserved; so we'll try to copy it from userSnippet
      let modelCopy = JSON.parse(JSON.stringify(model));
      if (!modelCopy.link && modelCopy.userSnippet?.link) {
        modelCopy.link = JSON.parse(JSON.stringify(modelCopy.userSnippet.link));
      }

      await addSnippet(modelCopy, user?.uid, classroomLanguage.code, model.id);

      if (exampleClassroomData && !user?.uid) {
        let snippets = await loadSnippets(null, learningLanguage.code, userLanguage.code)
        setExampleClassroomSnippetAdded(model.id)
        setUserSnippets(snippets)
      } 
    } catch (e) {
      console.log(e);
      toast({
        description: t("snippet.error_adding_snippet"),
        position: "top",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    } finally {
      setAddingSnippetKeys((prev) => ({
        ...prev,
        [model.id]: false,
      }));
    }
  }

  function getExampleUserRole() {
    return exampleClassroomData?.permissions?.[exampleUserID]?.role
  }

  function renderSnippetTable(index, snippetModel) {
    let exampleRole = getExampleUserRole()

    let base = "snippetItem"
    let id = null
    if (exampleRole) {
      id = `${base}_${exampleRole}_${index}`
    } else {
      id = `${base}_${index}`
    }

    return (
      <>
        <SnippetTable
          id={id}
          key={index}
          width="100%"
          languageCode={learningLanguage?.code}
          userLanguageCode={userLanguage?.code}
          minCellHeight={0}
          cellModel={snippetModel}
          savedVisibleSections={snippetModel.selectedSections}
          showMoreButton={canEditSnippet}
          onEditSnippet={(id) => onEditSnippetIDChanged(id)}
          togglePinSnippet={async (id, isPinned) => {
            if (!classroomSnippetsID) {
              await togglePinSnippet(id, isPinned);
            }
          }}
          canPinSnippet={canPinSnippet}
          canEditSnippet={canEditSnippet}
          canDeleteSnippet={canDeleteSnippet}
          selectedText={null}
          showAddSnippetButton={
            canAddSnippetItem && !userSnippetKeys[snippetModel.id]
          }
          onDeleteSnippet={(id) => {
            setDeletingSnippetID(id);
          }}
          onAddSnippetClick={(model) => onClickAddToMySnippets(model)}
        />
        <Box h="2px"></Box>
      </>
    );
  }

  async function onDeleteSnippet() {
    if (!deletingSnippetID) {
      return;
    }

    try {
      setIsDeletingSnippet(true);

      let role = exampleClassroomData?.permissions?.[exampleUserID]?.role
      let isExampleClassroomStudent = (role === ClassroomRoleEnum.STUDENT)
      if (exampleUserID && exampleClassroomData && !isExampleClassroomStudent) {
        let data = copyJSONObject(exampleClassroomData)
        if (data?.classroomData?.classroomSnippets) {

          // example classroom
          let classroomSnippets = exampleClassroomData.classroomData.classroomSnippets ?? []
          data.classroomData.classroomSnippets = classroomSnippets.filter((snippet) => snippet.id !== deletingSnippetID)
          setExampleClassroomData(data)
        }

      } else if (classroomSnippetsID) {
        // classroom
        await deleteClassroomSnippet(classroomSnippetsID, deletingSnippetID);
      } else {
        // user snippet
        await deleteSnippet(
          deletingSnippetID,
          user?.uid,
          learningLanguage.code
        );
        setSnippetModels((prev) =>
          prev.filter((snippet) => snippet.id !== deletingSnippetID)
        );
      }
    } catch (error) {
      console.log(error);
      toast({
        description: error.message,
        position: "top",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    } finally {
      setIsDeletingSnippet(false);
      setDeletingSnippetID(null);
    }
  }

  return (
    <VStack
      id={getElementID("columnSnippets", (canAddSnippetItem ? "classroom": undefined), getExampleUserRole())}
      as="section"
      pl={isMobile ? 0 : 2}
      pr={isMobile ? 0 : 2}
      spacing={4}
      h="100%"
      w="100%"
      overflowY="auto"
      data-test='column-snippets-vstack'
    >
      <Box
        width="100%"
        h="100%"
        borderWidth="1px"
        borderRadius="md"
        overflow="hidden"
        data-test='column-snippets-box'
      >
        {/* Header */}

        <Box
          padding={2}
          borderBottomWidth="1px"
          h="45px"
          backgroundColor="darkPurple"
          position="relative"
        >
          {isMobile && mobileSearchMode && (
            <MobileSnippetsSearch
              onShow={showMobileSearchID}
              onClose={() => {
                setMobileSearchMode(false);
              }}
              onTextChange={(text) => {
                setFilterSnippetsText(text);
              }}
            />
          )}

          {!mobileSearchMode && (
            <Box w="100%" h="100%">
              {/* more button  */}
              <Menu>
                <MenuButton
                  zIndex={10}
                  as={IconButton}
                  width={"24px"}
                  height={"24px"}
                  icon={<Image src="/icons/more.png" boxSize="16px" />}
                  _hover={{ bg: "lightPurple" }}
                  variant="ghost"
                  ml="auto"
                  data-test='column-snippets-menu-button'
                />

                <MenuList bgColor="darkPurple">
                  <MenuItem
                    backgroundColor="darkPurple"
                    _hover={{ bg: "lightPurple" }}
                    color="white"
                    data-test='menu-item-sort-alphabetically'
                    icon={
                      sortOption === Constants.SORT_OPTION_ALPHABETICALLY ? (
                        <Icon as={CheckIcon} boxSize={4} />
                      ) : null
                    }
                    onClick={() =>
                      handleSortSelect(Constants.SORT_OPTION_ALPHABETICALLY)
                    }
                  >
                    <Flex justifyContent="space-between" height="100%">
                      <Text height="100%">
                        {t("sentences.sort_alphabetically")}
                      </Text>
                      {sortOption === Constants.SORT_OPTION_ALPHABETICALLY &&
                        renderSortOrderIcon()}
                    </Flex>
                  </MenuItem>
                  <MenuItem
                    backgroundColor="darkPurple"
                    _hover={{ bg: "lightPurple" }}
                    color="white"
                    data-test='menu-item-sort-by-date'
                    icon={
                      sortOption === Constants.SORT_OPTION_BY_DATE ? (
                        <Icon as={CheckIcon} boxSize={4} />
                      ) : null
                    }
                    onClick={() =>
                      handleSortSelect(Constants.SORT_OPTION_BY_DATE)
                    }
                  >
                    <Flex justifyContent="space-between" height="100%">
                      <Text height="100%">
                        {t("sentences.sort_by_date_added")}
                      </Text>
                      {sortOption === Constants.SORT_OPTION_BY_DATE &&
                        renderSortOrderIcon()}
                    </Flex>
                  </MenuItem>
                </MenuList>
              </Menu>
              {canEditSnippet && (
                <IconButton
                  icon={<PlusSquareIcon />}
                  // color="white"
                  color='#42F57B'
                  backgroundColor="none"
                  colorScheme="none"
                  zIndex="1"
                  top={0}
                  height="100%"
                  _hover={{ bg: "lightPurple" }}
                  data-test='add-snippet-button'
                  onClick={onClickAddSnippet}
                ></IconButton>
              )}
              {/* Use Flex to center the text */}
              <Flex
                justifyContent="center"
                alignItems="center"
                position="absolute"
                left="0"
                right="0"
                top="0"
                bottom="0"
              >
                <Text fontSize="lg" fontWeight="bold" color="white">
                  {customTitleKey ? t(customTitleKey) : t("sentences.snippets")}
                </Text>
              </Flex>

              {/* InputGroup for search field */}
              {!isMobile && (
                <InputGroup
                  size="md"
                  position="absolute"
                  right="4px"
                  top="50%"
                  transform="translateY(-50%)"
                  maxWidth="25%"
                >
                  <Input
                    ref={filterSnippetsRef} // Attach the ref to the Input
                    color="white"
                    borderRadius="3xl"
                    value={filterSnippetsText}
                    data-test='filter-snippets-input'
                    placeholder={t("sentences.filter_snippets")}
                    onChange={(e) => setFilterSnippetsText(e.target.value)}
                  />
                  <InputRightElement width="36px">
                    <IconButton
                      h="1.75rem"
                      size="sm"
                      colorScheme="clear"
                      data-test='clear-filter-snippets-button'
                      icon={<CloseIcon color="lightGrey" borderRadius="lg" />}
                      onClick={clearFilterSnippetsAndFocus}
                    />
                  </InputRightElement>
                </InputGroup>
              )}

              {isMobile && (
                <IconButton
                  onClick={() => {
                    setMobileSearchMode(true);
                  }}
                  colorScheme="clear"
                  icon={<IoSearch />}
                  position="absolute"
                  top="0px"
                  height="100%"
                  right="4px"
                />
              )}
            </Box>
          )}
        </Box>
        <Box
          p={isMobile ? 0 : 1}
          width="100%"
          height="calc(100% - 45px)"
          overflowY="auto"
        >
          {!sortedAndFilteredSnippets && (
            <Flex mt={2} justifyContent="center">
              <Spinner color="grey" />{" "}
            </Flex>
          )}
          {sortedAndFilteredSnippets &&
            sortedAndFilteredSnippets.length === 0 && (
              <Box
                p={4}
                m={4}
                borderRadius="md"
                textAlign="center"
                backgroundColor="#FFFFFF10"
                h
                color="white"
              >
                {t("sentences.no_snippets_yet")}
              </Box>
            )}
          {(sortedAndFilteredSnippets ?? []).map((snippetModel, index) =>
            renderSnippetTable(index, snippetModel)
          )}
        </Box>
        <DeleteConfirmation
          isOpen={deletingSnippetID}
          isDeleting={isDeletingSnippet}
          onClose={() => {
            setDeletingSnippetID(null);
          }}
          onConfirm={() => {
            onDeleteSnippet();
          }}
          title="Delete Snippet"
          text="Are you sure you want to delete this snippet? This action cannot be undone."
        />
      </Box>
    </VStack>
  );
}

export default ColumnSnippets;

import { Flex } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FlagView } from "./flag-view";

function FlagList({ flags }) {
  const [allFlags, setFlags] = useState([]);

  useEffect(() => {
    setFlags(flags);
  }, [flags]);

  return (
    <Flex direction='column'>
      {allFlags.map((flag) => (
        <FlagView key={flag.title} title={flag.title} color={flag.color} />
      ))}
    </Flex>
  );
}

export default FlagList;
import { get, push, ref, set, update } from "@firebase/database";
import { database, functions } from "../../api/firebaseConfig";
import { copyJSONObject, isDefined, random8ID, randomID } from "../../utils/utils";
import { httpsCallable } from "@firebase/functions";

// Add story

export async function generateStoryTextAndSaveToDB(languageCode, level) {
  let callable = httpsCallable(functions, "story-generate_stories")
  let result = await callable({
    [languageCode]: {
      [level]: {
        count: 1
      }
    }
  })

  console.log("Generated Story: " + JSON.stringify(result))
  return result.data
}

export async function generateStoryText(languageCode, level) {
  let callable = httpsCallable(functions, "story-generate_story")
  let result = await callable({
    languageCode: languageCode,
    level: level
  })

  console.log("Generated Story: " + JSON.stringify(result))
  return result.data
}

export function addGeneratedStory(languageCode, level, story) {
  const path = `/story_creation_data/stories/${languageCode}/`
  console.log("Adding story at path " + path + " with data " + JSON.stringify(story))
  return push(ref(database, path), story)
}

export function addPlainTextStory(level, languageCode, story) {
  const lowercasedLevel = level.toLowerCase()
  const path = `/story_creation_data/stories/${languageCode}`
  console.log("Adding story at path " + path + " with data " + JSON.stringify(story))
  return push(ref(database, path), story)
}

// Generate mappings

export function getGenerateMappingStories(languageCode) {
  const path = `/story_creation_data/stories/${languageCode}`
  console.log("Fetching data at path " + path)
  const storiesRef = ref(database, path);

  let reviewStoriesPromise = getReviewStories(languageCode)

  let getStoriesPromise = get(storiesRef).then(snapshot => {
    let dict = snapshot.val()

    // console.log("Fetched Generate Mapping stories: " + JSON.stringify(levelDict))
    let stories = []
    // for (let levelKey in levelDict) {
    //   let dict = levelDict[levelKey]
      for (let key in dict) {
        let story = dict[key]
        if (story.mappingsGenerated) {
          continue
        }
        story.id = key
        console.log("After adding temp IDs: " + JSON.stringify(story))

        stories.push(story)
      }
    // }

    return stories
  }).catch(error => {
    console.error('Error loading Generate Mapping stories: ' + error)
    return []
  })

  return Promise.all([reviewStoriesPromise, getStoriesPromise]).then(([reviewStories, stories]) => {
    // for(let story of stories) {
    //   let reviewStory = reviewStories.find(reviewStory => reviewStory.id === story.id)
    //   console.log(`trying to find review story for story ${story.id} title ${story.title}, found: ${JSON.stringify(reviewStory)}`)
    //   story.reviewStory = reviewStory
    // }
    return stories
  })
}

export async function uploadReviewStoryImage(imageBase64, storyID, languageCode, level) {
  let callable = httpsCallable(functions, "story-upload_image_for_review_story")
  let result = await callable({
    imageBase64: imageBase64,
    storyID: storyID,
    languageCode: languageCode,
    level: level.toLowerCase()
  })

  console.log("Uploaded image: " + JSON.stringify(result))
  let imageURLPath = result.data?.imageURLPath ?? result.imageURLPath
  return imageURLPath
}

export async function submitGeneratedMappings(languageCode, level, storyKey, mappingsDict) {
  let lowercasedLevel = level.toLowerCase()
  const path = `/story_creation_data/mappings/${languageCode}/${storyKey}`
  console.log("Adding mappings at path " + path + " with data " + JSON.stringify(mappingsDict))
  try {
    await set(ref(database, path), mappingsDict)

    // update story; set mappingsGenerated to true
    let storyPath = `/story_creation_data/stories/${languageCode}/${storyKey}`
    console.log("Updating story at path " + storyPath + " with data " + true)

    return update(ref(database, storyPath), {
      mappingsGenerated: true
    })
  } catch (error) {
    console.error('Error submitting generated mappings: ' + error)
    throw error
  }
}

export async function updateStoryJSON(storyID, languageCode, level, json) {
  let callable = httpsCallable(functions, "story-update_story_json")
  let result = await callable({
    storyID: storyID,
    languageCode: languageCode,
    level: level,
    story: json
  })

  console.log("Updated story JSON: " + JSON.stringify(result))
  return result.data
}

export async function generateMappings(languageCode, level, storyID) {
  let callable = httpsCallable(functions, "story-generate_story_mappings")
  let result = await callable({
      languageCode: languageCode,
      level: level,
      storyID: storyID
  })

  console.log("Generated mappings: " + JSON.stringify(result))
  console.log("Returning data " + JSON.stringify(result.data))
  return result.data
}

// Review
export function getReviewStories(languageCode) {
  const path = `/story_creation_data/mappings/${languageCode}`
  console.log("Fetching data at path " + path)
  const storiesRef = ref(database, path);
  return get(storiesRef).then(snapshot => {
    let dict = snapshot.val()

    // console.log("Fetched Review stories: " + JSON.stringify(levelDict))
    let stories = []
    // for (let levelKey in levelDict) {
      // let dict = levelDict[levelKey]
      for (let key in dict) {
        let story = dict[key]

        if(story.isPublished) {
          continue
        }

        story.id = key
        story.tempLevel = story.level.toLowerCase()
        story.tempLanguageCode = languageCode
      
        // sort mappings
        let sentences = story.sentences ?? []
        for(let sentence of sentences) {
          if(sentence.mappings) {
            let filtered = sentence.mappings.filter(mapping => mapping)
            sentence.mappings = filtered.sort((a, b) => a.range.location - b.range.location)
          }
        }

        stories.push(story)
      }
    // }

    console.log("Fetched review stories: " + JSON.stringify(stories, null, 4))

    return stories
  }).catch(error => {
    console.error('Error loading Review stories: ' + error)
    return []
  })
}

export async function updateReviewStory(story) {
  const languageCode = story.tempLanguageCode
  const level = story.tempLevel
  const id = story.id

  if (!languageCode || !level || !id) {
    console.error("Missing language code, level or id for story, can't update " + JSON.stringify(story))
    throw new Error("Missing language code, level or id for story, can't update " + JSON.stringify(story))
  }
  const path = `/story_creation_data/mappings/${languageCode}/${id}`

  let storyCopy = prepareStoryForSaving(story)
  console.log("Updating story at path " + path + " with data " + JSON.stringify(storyCopy))
  return set(ref(database, path), storyCopy)
}

export async function publishStory(storyID, languageCode, level) {
  const callable = httpsCallable(functions, "story-publish_story")
  return callable({
    storyID: storyID,
    languageCode: languageCode,
    level: level
  })
}

function prepareStoryForSaving(story) {
  let copy = copyJSONObject(story)
  // remove all temp fields
  delete copy.tempLevel
  delete copy.tempLanguageCode

  return copy
}


// utils

export function addStoryTempIDs(story) {
  addTempIDs(story, ['mappings', 'snippets'])
}

export function addTempIDs(dict, keys) {
  for (let key in dict) {
    let obj = dict[key]
    if (keys.includes(key) && Array.isArray(obj)) {
      for (let item of obj) {
        if (typeof item === 'object' && !item.id) {
          item.id = random8ID()
        }
      }
    }

    if (typeof obj === 'object' || Array.isArray(obj)) {
      addTempIDs(obj, keys)
    }
  }
}

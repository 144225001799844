import { child, endAt, get, orderByChild, query, ref, startAt } from "@firebase/database";
import { database as db, functions} from "./firebaseConfig";
import { httpsCallable } from "@firebase/functions";
import { Constants } from "../utils/constants";

export async function uploadStory(story, languageCode) {
    let uploadStoryCallable = httpsCallable(functions, 'story-upload_story');
    let data = {
        story,
        languageCode
    }
    return uploadStoryCallable(data);
}

export async function getStory(storyId, languageCode) {
    const storyRef = ref(db, `/story/${languageCode}/${storyId}`);
    return get(storyRef).then(snapshot => snapshot.val());
}

export async function searchStories(
    title, 
    userLanguageCode, 
    storyLanguageCode, 
    difficulties, 
    levels, 
    tags, 
    sortBy, 
    page, 
    limitPerPage
) {
    
    const storiesRef = ref(db, `/story_descriptors/${storyLanguageCode}`);
    const tagsRef = ref(db, `/story_tags`);
  
    let stories = [];
    let filteredStories = [];
  
    // Prepare queries in parallel
    const titleQueryPromise = title 
      ? get(query(storiesRef, orderByChild(`title_translations/${userLanguageCode}`), startAt(title), endAt(title + "\uf8ff")))
      : get(storiesRef);
  
    const tagQueryPromises = tags && tags.length > 0 
      ? tags.map(tag => get(child(tagsRef, tag)))
      : [];

    // Fetch stories and tags in parallel
    const [titleQuerySnapshot, ...tagSnapshots] = await Promise.all([titleQueryPromise, ...tagQueryPromises]);
  
    // Extract stories from the title query
    titleQuerySnapshot.forEach(snapshot => {
      stories.push({ id: snapshot.key, ...snapshot.val() });
    });
  
    // Filter by difficulty or level
    if (difficulties && difficulties.length > 0) {
      filteredStories = stories.filter(story => difficulties.includes(story.difficulty));
    } else if (levels && levels.length > 0) {
      filteredStories = stories.filter(story => levels.includes(story.level));
    } else {
      filteredStories = stories;
    }
  
    // Filter by tags if provided
    if (tags && tags.length > 0) {
      let storyIdsWithTag = new Set();
  
      tagSnapshots.forEach(tagSnapshot => {
        const tagStories = Object.keys(tagSnapshot.val() || {});
        tagStories.forEach(storyId => storyIdsWithTag.add(storyId));
      });
  
      filteredStories = filteredStories.filter(story => storyIdsWithTag.has(story.id));
    }
  
    function sortAlphabetically(a, b) {
      const titleA = (a.title_translations?.[userLanguageCode] || a.title_translations?.[storyLanguageCode]) || a.title
      const titleB = (b.title_translations?.[userLanguageCode] || b.title_translations?.[storyLanguageCode]) || b.title
      return titleA.localeCompare(titleB);
    }

    // Sort the stories
    if (sortBy === 'created_at') {
      filteredStories.sort((a, b) => b.created_at - a.created_at); // Sort by creation date descending
    } else if (sortBy === 'alphabetical') {
      filteredStories.sort(sortAlphabetically);

    } else if (sortBy === 'level') {
      // level and alphabet
      const levels = ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'];
      filteredStories.sort((a, b) => {
        const result = levels.indexOf(a.level) - levels.indexOf(b.level)
        if(result === 0) {
          return sortAlphabetically(a, b)
        } else {
          return result
        }
      });
    }
  
    // Paginate the results
    // const start = (page - 1) * limitPerPage;
    // const end = start + limitPerPage;
    // const paginatedStories = filteredStories.slice(start, end);
  
    console.log('Stories: ', filteredStories);
    for(const story of filteredStories) {
      story.title = story.title_translations?.[userLanguageCode] || story.title
      story.languageCode = storyLanguageCode;
      if(story.imageURLPath) {
        story.imageURL = Constants.storageURLWithPath(story.imageURLPath)
      } else {
        story.imageURL = null
      }
      
    }

    return filteredStories
}

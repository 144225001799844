import { Heading, Textarea, VStack, useMediaQuery } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { classroomRoleState } from "../state/classroom-state";
import { useRecoilState } from "recoil";
import { ClassroomRoleEnum } from "./classrooms/classroom-role";
import { Constants } from "../utils/constants";
import { exampleClassroomState } from "./classrooms/example-classroom-state";

function ClassNotes({id, titleID, notes, canEditNotes, onEditNotes, showTitle}) {
    const [isMobile] = useMediaQuery(Constants.MEDIA_QUERY_MOBILE);
    const [canEdit, setCanEdit] = useState(canEditNotes ?? true ); 
    const [classNotes, setClassNotes] = useState(''); // Add state for class notes
    const [classroomRole, setClassroomRole] = useRecoilState(classroomRoleState) 
    
    const { t } = useTranslation();

    useEffect(() => {
        setClassNotes(notes)
    }, [notes])

    function onEdit(newText) {
        console.log(`role ${classroomRole} onEdit ${newText}`)
        // if(classroomRole === ClassroomRoleEnum.STUDENT) {
        //     return;
        // }

        setClassNotes(newText)
        onEditNotes?.(newText)
    }

    return (
        <VStack w="100%" h='calc(100% - 16px)' spacing={4} alignItems="start" data-test='class-notes-wrapper'>
            { showTitle && <Heading id={titleID ?? 'classNotesTitle'} data-test='class-notes-title' w='100%' color='white' as="h1" size="md" textAlign="center" align="center">{t('class_notes.title')}</Heading> }
            <Textarea
                id={id ?? 'classNotes'}
                data-test="class-notes-textarea"
                color='white'
                spellCheck={false}
                fontSize="medium"
                width="100%"
                variant={ isMobile ? 'unstyled' : 'outline' }
                placeholder={t('class_notes.placeholder')}
                value={classNotes ?? ''}
                onChange={(e) => { onEdit(e.target.value) }}
                isReadOnly={!canEdit} //ee Set isReadOnly property to "true" if canEdit is false
                resize="none" // Set resize property to "none" to make it not resizable
                flex="1" // Set flex property to "1" to take the rest of the vertical spacing
            />
        </VStack>
    )
}

export default ClassNotes;
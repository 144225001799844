import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Select,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { isDefined  } from "../../utils/utils";
const TemplateSelectorModal = ({ isOpen, onClose, wordName, onApplyTemplate }) => {
  const { t } = useTranslation();

  const templateKeys = [
    "female_character_name",
    "female_name",
    "lastname",
    "location_from_book",
    "location_from_movie",
    "male_character_name",
    "male_name",
    "city_name",
    "country_name",
    "city_area",
    "city_landmark",
    "person_lastname",
    "person_name",
  ];

  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [applyToAll, setApplyToAll] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setSelectedTemplate(null);
      setApplyToAll(false);
    }
  }, [isOpen]);

  const handleApply = () => {
    if (onApplyTemplate) {
      onApplyTemplate(selectedTemplate, wordName, applyToAll);
    }
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} color='white'>
      <ModalOverlay />
      <ModalContent color='white' backgroundColor='purple'>
        <ModalHeader>Template Selector</ModalHeader>
        <ModalCloseButton />
        <ModalBody colorScheme="purple" >
          <Text mb={4}>{wordName}</Text>

          <FormControl>
            <FormLabel>Template</FormLabel>
            <Select
              placeholder={"Select a template"}
              value={selectedTemplate || ""}
              onChange={(e) => setSelectedTemplate(e.target.value || null)}
            >
              {templateKeys.map((key) => (
                <option key={key} value={key}>
                  {t(`story_snippet_templates.${key}`)}
                </option>
              ))}
            </Select>
          </FormControl>

          <Checkbox
            mt={4}
            isChecked={applyToAll}
            onChange={(e) => setApplyToAll(e.target.checked)}
          >
            Add to all words
          </Checkbox>
        </ModalBody>

        <ModalFooter>
          <Button isDisabled={!isDefined(selectedTemplate)} colorScheme="blue" onClick={handleApply}>
            Apply
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TemplateSelectorModal;

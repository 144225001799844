import React, { useRef } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
  Table,
  Tbody,
  Tr,
  Td,
  IconButton,
  Collapse,
  Checkbox,
  Text,
  Box,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Show,
} from "@chakra-ui/react";
import {
  isTextHighlighted,
  accentInsensitiveRegex,
} from "../utils/snippet-utils";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import WordInfoDialog from "../dialogs/word-info-dialog";
import { useRecoilState } from "recoil";
import { addingSnippetKeysState } from "../state/snippets-state";
import { cellSizeState } from "../state/cell-size-state";
import SnippetHeader from "./snippet-header";
import EditSnippetHeader from "./edit-snippet-header";
import { copyJSONObject, isDefined } from "../utils/utils";

function SnippetTable({
  id,
  customTitle,
  cellModel,
  editMode,
  savedVisibleSections,
  languageCode,
  userLanguageCode,
  togglePinSnippet,
  snippetKey,
  hideCollapseSections,
  hideCollapseSnippet,
  showMoreButton,
  displayAllSections = false,
  canPinSnippet = true,
  canDeleteSnippet = true,
  canEditSnippet = true,
  hideHeader,
  editHeaderModel,
  isInitiallyExpanded,
  selectedText,
  isExactMatchSelectedText,
  includeNonHighlightedSections,
  showSelectedTextOnlyOnExactMatch,
  showAddSnippetButton,
  onAddSnippetClick,
  onEditHeaderChanged,
  onModelChanged,
  onEditSectionsChanged,
  onEditSnippet,
  onDeleteSnippet,
  onLinkClick,
}) {
  const [cellSizeCache, setCellSizeCache] = useRecoilState(cellSizeState);
  const [isAddingSnippet, setIsAddingSnippet] = useState(false);
  const [canAddSnippetItem, setCanAddSnippetItem] =
    useState(showAddSnippetButton);
  const [addingSnippetKeys, setAddingSnippetKeys] = useRecoilState(
    addingSnippetKeysState
  );
  const [isExpanded, setIsExpanded] = useState(false);
  const [expandedState, setExpandedState] = useState(false); // expanded state depends on multiple factors, so we need to combine it into one state
  const [model, setModel] = useState(cellModel);
  const [editedModel, setEditedModel] = useState(cellModel);
  const [sections, setSections] = useState([]);
  const [readSectionsExpanded, setReadSectionsExpanded] = useState({});
  const [editSectionsVisible, setEditSectionsVisible] = useState({});
  const [editingSnippetID, setEditingSnippetID] = useState(snippetKey);
  const [showSnippetTitle, setShowSnippetTitle] = useState(true);
  const [updateHiddenSections, setUpdateHiddenSections] = useState(false);
  // const { getWidthsFor, clearCache } = SizeCalculator()

  const [sectionIDs, setSectionIDs] = useState(null);
  const [widthsCalculated, setWidthsCalculated] = useState(false);
  const [highlightedText, setHighlightedText] = useState(null);

  const [popoverSectionID, setPopoverSectionID] = useState(null);
  const [selectedPopover, setSelectedPopover] = useState(null);
  const [doubleSelectedText, setDoubleSelectedText] = useState(null);
  const isPopoverOpen = (text) => selectedPopover === text;

  const popoverRef = useRef(null);

  // for debugging
  const opacityCalculatingWidths = 0.1;
  const opacityHiddenSection = 0.4;
  const showCellColors = false;
  const showTableBorder = false;
  const tableBorder = showTableBorder ? "1px" : "0px";
  const selectedTextBackgroundColor = 'rgba(245, 245, 245, 0.75)'

  const [loading, setLoading] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    setCanAddSnippetItem(showAddSnippetButton);
  }, [showAddSnippetButton]);

  useEffect(() => {
    setWidthsCalculated(false);
  }, [isExpanded]);

  useEffect(() => {
    if (cellModel?.id && addingSnippetKeys[cellModel.id]) {
      setIsAddingSnippet(true);
    } else {
      setIsAddingSnippet(false);
    }
  }, [cellModel, addingSnippetKeys]);

  useEffect(() => {
    if (highlightedText && includeNonHighlightedSections !== true) {
      setExpandedState(true);

      let hasHighlightedEntries = false;
      for (let section of sections) {
        // if (section.type === 'table') {
        hasHighlightedEntries =
          hasHighlightedEntries ||
          testSnippetHasHighlightedTextInSections(section);
        // }
      }

      let headerText = cellModel?.header?.text;
      let isTitleHighlighted = isTextHighlighted(headerText, highlightedText);

      setShowSnippetTitle(isTitleHighlighted || hasHighlightedEntries);
    } else {
      setShowSnippetTitle(true);
      setExpandedState(isExpanded);
    }
  }, [isExpanded, highlightedText]);

  useEffect(() => {
    if (!sectionIDs) {
      return;
    }

    let timers = [];

    for (let i = 1; i <= 2; i++) {
      const timer = setTimeout(() => {
        setMaxColumnWidth();
        setLoading(false);
      }, i * 200);
      timers.push(timer);
    }

    return () => {
      for (let timer of timers) {
        clearTimeout(timer);
      }
    };
  }, [sectionIDs]);

  useEffect(() => {
    if (isInitiallyExpanded) {
      setIsExpanded(true);
    }
  }, [isInitiallyExpanded]);

  useEffect(() => {
    setHighlightedText(selectedText);
  }, [selectedText]);

  useEffect(() => {
    setEditingSnippetID(snippetKey);
  }, [snippetKey]);

  useEffect(() => {
    if (cellModel && languageCode) {
      setModel(cellModel);
      setEditedModel(cellModel);
      setSections(cellModel.items ?? []);
      let sectionIDs = cellModel.items?.map((section) => section.id) ?? [];
      sectionIDs.push("main");
      setSectionIDs(sectionIDs);

      let visibleSections = copyJSONObject(savedVisibleSections ?? {})
      visibleSections['main'] = true
      setEditSectionsVisible(visibleSections);

      setReadSectionsExpanded(visibleSections);
      // if (!snippetKey) {
      //   // load hidden sections from local storage
      //   let hiddenSections = {};
      //   let key = `hiddenSections_${languageCode}`;
      //   let hiddenSectionNames = localStorage.getItem(key);
      //   if (hiddenSectionNames) {
      //     getSectionIDsFromSectionNames(
      //       cellModel,
      //       JSON.parse(hiddenSectionNames)
      //     ).forEach((key) => {
      //       hiddenSections[key] = true;
      //     });
      //   } else {
      //   }

      //   setEditSectionsVisible(hiddenSections);
      // } else {
      //   let hiddenSections = [];
      //   getHiddenSectionsIDsFromModel(model, hiddenSections);
      //   let hiddenSectionsDict = {};
      //   hiddenSections.forEach((sectionID) => {
      //     hiddenSectionsDict[sectionID] = true;
      //   });
      //   setEditSectionsVisible(hiddenSectionsDict);
      // }

      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [cellModel, languageCode, snippetKey, savedVisibleSections]);

  useEffect(() => {
    modelDidChange();
  }, [editedModel]);

  useEffect(() => {
    updateEditedModel();
  }, [editSectionsVisible, editMode]);

  function toggleEditSection(key, value) {
    let newSections = { ...editSectionsVisible, [key]: value }
    setEditSectionsVisible(newSections);
    setUpdateHiddenSections(true);
    onEditSectionsChanged?.(newSections);
  }

  function updateEditSectionKeys(sections) {
    // when the edit sections change, we want to save keys to local storage for the snippet language,
    // so that when the user returns to the page for that language, the same sections are hidden

    if (!languageCode || !updateHiddenSections) {
      return;
    }

    let hiddenSections = [];
    getHiddenSectionsFromModel(sections, hiddenSections);

    let hiddenSectionNames = [];
    hiddenSections = hiddenSections.forEach((section) => {
      let sectionName = getHiddenSectionName(section);
      if (sectionName) {
        hiddenSectionNames.push(sectionName);
      }
    });

    let key = `hiddenSections_${languageCode}`;
    localStorage.setItem(key, JSON.stringify(hiddenSectionNames));
    setUpdateHiddenSections(false);
  }

  function getSectionIDsFromSectionNames(model, sectionNames) {
    if (!sectionNames) {
      return [];
    }
    let sectionIDs = [];
    for (let key in model) {
      if (typeof model[key] == "object") {
        let sectionName = getHiddenSectionName(model[key]);
        if (sectionName && sectionNames.some((name) => name === sectionName)) {
          sectionIDs.push(model[key].id);
        } else {
          sectionIDs = sectionIDs.concat(
            getSectionIDsFromSectionNames(model[key], sectionNames)
          );
        }
      } else if (Array.isArray(model[key])) {
        model[key].forEach((item) => {
          sectionIDs = sectionIDs.concat(
            getSectionIDsFromSectionNames(item, sectionNames)
          );
        });
      }
    }
    return sectionIDs;
  }

  function getHiddenSectionName(section) {
    if (!section) {
      return null;
    }
    if (section.subheader?.text) {
      return section.subheader.text;
    } else if (section.header?.text) {
      return section.header.text;
    } else {
      return null;
    }
  }

  function getHiddenSectionsIDsFromModel(model, hiddenSections = []) {
    if (typeof model == "object") {
      if (model.hidden && model.id) {
        hiddenSections.push(model.id);
        return;
      } else {
        for (let key in model) {
          getHiddenSectionsIDsFromModel(model[key], hiddenSections);
        }
      }
    } else if (Array.isArray(model)) {
      model.forEach((item) => {
        getHiddenSectionsIDsFromModel(item, hiddenSections);
      });
    }
  }

  function getHiddenSectionsFromModel(model, hiddenSections = []) {
    if (typeof model == "object") {
      if (model.hidden) {
        hiddenSections.push(model);
        return;
      } else {
        for (let key in model) {
          getHiddenSectionsFromModel(model[key], hiddenSections);
        }
      }
    } else if (Array.isArray(model)) {
      model.forEach((item) => {
        getHiddenSectionsFromModel(item, hiddenSections);
      });
    }
  }

  function updateEditedModel() {
    if (!editMode) {
      return;
    }
    // filter cell model based on hidden sections and assign to editedModel

    // recursively filter sections from editSectionsHidden in the sections object
    // and assign to editedModel

    let visibleSections = {}
    if (editSectionsVisible) {
      visibleSections = JSON.parse(JSON.stringify(editSectionsVisible))
    }
    
    // for (let key in editSectionsVisible) {
    //   if (!editSectionsVisible[key]) {
    //     console.log(`hiddenSection[${key}] = true`)
    //     visibleSections[key] = true;
    //   }
    // }

    let sectionsCopy = JSON.parse(JSON.stringify(sections));

    let filteredSections = filterSections(sectionsCopy, visibleSections);

    updateEditSectionKeys(filteredSections);

    let newEditedModel = { ...cellModel, items: filteredSections };
    setEditedModel(newEditedModel);
  }

  function filterSections(object, visibleSections) {
    if (typeof object == "object") {
      if (object.id) {
        if (visibleSections[object.id]) {
          object.hidden = false;
        } else {
          object.hidden = true;
        }
      }

      for (let key in object) {
        object[key] = filterSections(object[key], visibleSections);
      }

      return object;
    } else if (Array.isArray(object)) {
      let arr = [];
      object.forEach((item) => {
        let filteredItem = filterSections(item, visibleSections);
        if (filteredItem) {
          arr.push(filteredItem);
        }
      });
      return arr;
    } else {
      return object;
    }
  }

  const toggleSnippetCollapsed = () => {
    setIsExpanded(!isExpanded);
  };

  const toggleSection = (index) => {
    setReadSectionsExpanded({
      ...readSectionsExpanded,
      [index]: !readSectionsExpanded[index],
    });
  };

  function modelDidChange() {
    onModelChanged?.(editedModel);
  }

  function renderSnippets() {
    let values = [50, 150, 400];
    for (let timeout of values) {
      setTimeout(() => {
        changeOpacity();
        setMaxColumnWidth();
      }, timeout);
    }
    let collapsed = false;

    if (!sections) {
      return <></>;
    }

    return (
      <>
        {sections.map((section, sectionIndex) => {
          if (collapsed || !expandedState) {
            return <></>;
          } else if (!editMode && section.collapsesSnippet) {
            if (section.collapsesSnippet && readSectionsExpanded[section.id]) {
              collapsed = true;
            }
            return <></>;
          } else if (section.type === "table") {
            return <>{renderTable(section)}</>;
          }

          return <></>;
        })}
      </>
    );
  }

  function renderMoreMenu(snippetID, isPinned, canPinSnippet, canEditSnippet, canDeleteSnippet) {
    let canShow = canPinSnippet || canEditSnippet || canDeleteSnippet
    if (!canShow) {
      return <Box w="40px" h="24px"></Box>;
    } else {
      return (
        <Menu data-test='more-menu'>
          <MenuButton
            data-test='more-menu-button'
            as={IconButton}
            width={"24px"}
            height={"24px"}
            icon={<Image src="/icons/more.png" boxSize="16px" />}
            _hover={{ bg: "lightPurple" }}
            variant="ghost"
            ml="auto"
          />
          <MenuList bgColor="darkPurple" data-test='more-menu-list'>
            {canPinSnippet && (
              <MenuItem
                data-test='more-item-pin'
                backgroundColor="darkPurple"
                _hover={{ bg: "lightPurple" }}
                color="white"
                onClick={async () => {
                  await togglePinSnippet?.(snippetID, isPinned);
                }}
              >
                {isPinned
                  ? t("sentences.unpin_snippet")
                  : t("sentences.pin_snippet")}
              </MenuItem>
            )}
            {canEditSnippet && <MenuItem
              data-test='more-item-edit'
              backgroundColor="darkPurple"
              _hover={{ bg: "lightPurple" }}
              color="white"
              onClick={() => {
                onEditSnippet?.(snippetID);
              }}
            >
              {t("sentences.edit")}
            </MenuItem>}
            {canDeleteSnippet && <MenuItem
              data-test='more-item-delete'
              backgroundColor="darkPurple"
              _hover={{ bg: "lightPurple" }}
              color="red"
              onClick={() => onDeleteSnippet?.(snippetID)}
            >
              {t("sentences.delete")}
            </MenuItem>}
          </MenuList>
        </Menu>
      );
    }
  }

  function renderSnippetEditableTitle(editModel) {
    return (
      <EditSnippetHeader
        editModel={editModel}
        term={editModel.term}
        definitions={editModel.definitions}
        selectedDefinitionID={editModel.selectedDefinitionID}
        customDefinitionText={editModel.customDefinitionText}
        onChange={(editModel) => { onEditHeaderChanged?.(editModel) }}
        expandedState={expandedState}
        canCollapse={editModel.collapsesSnippet}
        toggleSnippetCollapsed={toggleSnippetCollapsed}
      />
    )
  }

  function renderSnippetTitle2(snippet, canPinSnippet) {

    let linkData = null

    if (snippet.inflectionEntry) {
      linkData = {
        link: snippet.inflectionEntry
      }
    }

    if (editHeaderModel && !linkData) {
      return renderSnippetEditableTitle(editHeaderModel)
    }
    let shouldHideCollapseSnippet = hideCollapseSnippet ?? false

    let canCollapse = (snippet?.collapsesSnippet ?? false) && !shouldHideCollapseSnippet
  
    let term = snippet.term ?? snippet.plainTextTerm
    term = term ?? ''
    let translation = ''

    let text = null

    let invalidValueCharacter = '~'

    if (snippet.customSnippetText) {
      text = snippet.customSnippetText
    } else {
      if (snippet.selectedDefinition?.id && snippet.definitions) {
        // find the definition in the definitions dictionary with key snippet.selectedDefinitionID
        let definition = snippet.definitions[snippet.selectedDefinition.id]

        if (!definition) {
          // select first definition
          let minIndex = 10000
          let firstKey = null
          for (let defKey in snippet.definitions) {
            let definition = snippet.definitions[defKey]
            if (isDefined(definition.index) && definition.index < minIndex) {
              minIndex = definition.index
              firstKey = defKey
            }
          }

          if (firstKey && snippet.definitions[firstKey]) {
            definition = snippet.definitions[firstKey]
          }
        }

        if (definition) {
          let customTranslation = snippet.customTranslation
          translation = customTranslation ?? (definition.text ?? definition.raw)
        } else {

          translation = invalidValueCharacter
        }
      } else if (snippet.plainTextTranslation) {
        translation = snippet.plainTextTranslation
      } else if (snippet.customDefinition) {
        translation = snippet.customDefinition
      } else {
        translation = invalidValueCharacter
      }

      text = `${term} - ${translation}`
    }

    return (
      <SnippetHeader
        text={text}
        expandedState={expandedState}
        toggleSnippetCollapsed={toggleSnippetCollapsed}
        canCollapse={canCollapse}
        isAddingSnippet={isAddingSnippet}
        onAddSnippetClick={onAddSnippetClick}
        cellModel={cellModel}
        canAddSnippetItem={canAddSnippetItem}
        showAddSnippetButton={showAddSnippetButton}
        renderMoreMenu={renderMoreMenu}
        links={linkData}
        onLinkClick={onLinkClick}
        canPinSnippet={canPinSnippet}
        canEditSnippet={canEditSnippet}
        canDeleteSnippet={canDeleteSnippet}
      />
    );

  }

  function renderCheckboxChevronIcon(section, opacity) {
    if (hideCollapseSections) {
      return <></>;
    }

    return (
      <Box style={{ cursor: "default" }} opacity={opacity}>
        {editMode ? (
          <Checkbox
            style={{ cursor: "default" }}
            isDisabled={opacity === 0}
            defaultChecked={editSectionsVisible[section.id]}
            checked={editSectionsVisible[section.id]}
            onChange={(e) => {
              toggleEditSection(section.id, e.target.checked);
            }}
            mr={0} // Left margin to Checkbox to create some spacing from the text
          />
        ) : (
          <IconButton
            isDisabled={opacity === 0}
            color="white"
            width={"24px"}
            height={"24px"}
            icon={
              !readSectionsExpanded[section.id] ? (
                <ChevronUpIcon />
              ) : (
                <ChevronDownIcon />
              )
            }
            _hover={{ bg: "darkPurple" }}
            onClick={() => toggleSection(section.id)}
            aria-label="Toggle Subsection"
            variant="ghost"
            mr={0} // Left margin to IconButton to create some spacing from the text
          />
        )}
      </Box>
    );
  }

  function renderTable(snippet) {
    let filteredSections = [];

    let selectedSections = cellModel.selectedSections

    let showAllSections = displayAllSections || !selectedSections || editMode
    for (let section of snippet.sections) {

      if (showAllSections) {
        filteredSections.push(section)
      } else {
        if (selectedSections[section.id] || section.subheader) {
          filteredSections.push(section)
        }
      }
    }

    return (
      <Table
        width="calc(100% - 16px)"
        ml="2px"
        borderRadius="md"
        style={{
          backgroundImage: "url('/icons/table-background.png')",
          backgroundSize: "cover",
          backgroundRepeat: "repeat-y",
          backgroundPosition: "center center",
        }}
      >
        <Tbody key="main" style={{ width: "100%" }}>
          {filteredSections.map((section, rowIndex) => {
            if (!editMode && section.hidden) {
              return <></>;
            } else if (section.subheader) {
              return (
                <>
                  <Tr
                    key={snippet.id}
                    w="100%"
                    opacity={opacityCalculatingWidths}
                  >
                    <Td
                      border={tableBorder}
                      borderColor="white"
                      backgroundColor={showCellColors ? "orange" : null}
                      key={rowIndex}
                      colSpan={4}
                      textAlign="center"
                      p={1}
                    >

                      <Box
                        borderRadius="md"
                        w="100%"
                        h="100%"
                        p={2}
                        backgroundColor="midPurple"
                      >
                        <Flex align="center" mr={2}>
                          {renderCheckboxChevronIcon(section, 0)}
                          <Text color="white" textAlign="center" flexGrow={1}>
                            {text(section.subheader, "text")}
                          </Text>
                          {renderCheckboxChevronIcon(section, section.subheader.collapsable === false ? 0 : 1)}
                        </Flex>
                      </Box>
                    </Td>
                  </Tr>
                  {renderTableRows(section)}
                </>
              );
            } else if (section.header && section.value) {
              return renderHeaderValueSection(section);
            } else {
              return renderTableRows(section);
            }
          })}
        </Tbody>
      </Table>
    );
  }

  function renderHeaderValueSection(section) {
    // if (highlightedText && !testSectionHasHighlightedText(section)) {
    //   return <></>
    // }
    let uniqueIndex = section.id;
    return (
      <Tr
        key={section.id}
        w="100%"
        className="headerTr"
        data-test={`table_row_${section.id}`}
        opacity={opacityCalculatingWidths}
      >
        <Td
          border={tableBorder}
          borderColor="white"
          backgroundColor={showCellColors ? "orange" : null}
          key={section.id}
          data-test={`td_${section.id}`}
          colSpan={4}
          textAlign="center"
          p={1}
          onClick={() => {
            setPopoverSectionID(uniqueIndex);
          }}
        >
          <Box
            borderRadius="md"
            w="100%"
            h="100%"
            pl={4}
            pr={4}
            pb={2}
            pt={2}
            backgroundColor="midPurple"
          >
            <Flex
              align="center"
              opacity={
                editMode && !editSectionsVisible[section.id]
                  ? opacityHiddenSection
                  : 1
              }
            >
              <Text data-test={`header_text_${section.id}`} color="white" textAlign="center" width={"35%"} flexGrow={1}>
                {text(section.header, "text")}
              </Text>
              {renderText(
                section.value.text,
                highlightedText,
                !editMode,
                uniqueIndex
              )}

              {editMode && (
                <Checkbox
                  data-test={`checkbox_include_${section.id}`}
                  defaultChecked={editSectionsVisible[section.id]}
                  checked={editSectionsVisible[section.id]}
                  float="right"
                  onChange={(e) => {
                    toggleEditSection(section.id, e.target.checked);
                  }}
                />
              )}
            </Flex>
          </Box>
        </Td>
      </Tr>
    );
  }

  function testSnippetHasHighlightedTextInSections(snippet) {
    if (testSectionHasHighlightedText(snippet)) {
      return true;
    }

    for (let section of snippet.sections) {
      if (section.hidden) {
        continue;
      }

      if (section.subheader) {
        if (testSectionHasHighlightedText(section)) {
          return true;
        }
      } else if (section.header?.text && section.value?.text) {
        let highlighted = isTextHighlighted(
          section.value.text,
          highlightedText
        );
        if (highlighted) {
          return true;
        }
      }
    }

    return false;
  }

  function testSectionHasHighlightedText(section) {
    if (!highlightedText) {
      return false;
    }

    // check for header / value
    if (section.header?.text && section.value?.text) {
      let highlighted = isTextHighlighted(section.value.text, highlightedText);
      return highlighted;
    }

    let rows = section.rows ?? [];

    for (let row of rows) {
      for (let cell of row) {
        if (
          cell.type === "text" &&
          isTextHighlighted(cell.text, highlightedText)
        ) {
          return true;
        }
      }
    }
    return false;
  }

  function renderTableRows(section) {
    // if (highlightedText && !testSectionHasHighlightedText(section)) {
    //   return <></>
    // }


    let rows = section.rows ?? [];

    return rows.map((row, rowIndex) => {
      let show = !section.collapsable ||
            (editMode && editSectionsVisible[section.id]) ||
            (!editMode && readSectionsExpanded[section.id])
      // let show = false
      if(!show) {
        console.log('!show')
        return null
      }
      return (
        // <Collapse
        //   data-test={`collapse_${rowIndex}`}
        //   key={`collapse_${rowIndex}`}
        //   className={section.id}
        //   in={
        //     !section.collapsable ||
        //     (editMode && editSectionsVisible[section.id]) ||
        //     (!editMode && readSectionsExpanded[section.id])
        //   }
        // >
        //   <Box w="100%" h="100%" pl={0} pr={0} className="tr" data-test={`row_${rowIndex}`}>

            <Tr
              data-test={`table_row_${rowIndex}`}
              key={rowIndex}
              w="100%"
              display={"inline-flex"}
              opacity={opacityCalculatingWidths}
            >
              {row.map((cell, cellIndex) =>
                cell.type === "header"
                  ? renderTableHeaderCell(row, rowIndex, cell, cellIndex, row.length)
                  : renderTableTextCell(
                    row,
                    section.id,
                    rowIndex,
                    cell,
                    cellIndex,
                    highlightedText,
                    row.length
                  )
              )}
            </Tr> 
        //   </Box>
        // </Collapse>
      );
    }).filter((d) => d);
  }

  function renderTableTextCell(
    row,
    sectionIndex,
    rowIndex,
    cell,
    cellIndex,
    selectedText,
    cellCount
  ) {
    let isPersonCell = cell.type === "person";
    let uniqueIndex = sectionIndex + "_" + rowIndex + "_" + cellIndex;
    let text = cell.text
    let arr = []

    if (Array.isArray(text)) {
      arr = text
    } else {
      arr = [text]
    }

    if (cell.count && cell.index) {
      text = localizePerson(cell.count, cell.index);
    }

    let highlightText = selectedText;
    if (showSelectedTextOnlyOnExactMatch) {
      highlightText = selectedText === text ? selectedText : null;
    }

    return (
      <Td
        data-test={`table_cell_${cellIndex}`}
        border={tableBorder}
        borderColor="white"
        w={`${100/(cellCount ?? 1)}%`} 
        key={cellIndex}
        colSpan={cell.colSpan || 1}
        textAlign="center"
        alignContent="center"
        verticalAlign="middle"
        fontWeight="normal"
        whiteSpace={[
          "pre-wrap", // 0-30em
          "nowrap", // 62em+
        ]}
        onClick={() => {
          setPopoverSectionID(uniqueIndex);
        }}
      >
        {arr.map((text) => {
          return <>
            <Show above="sm">
              <Box>
                {renderText(
                  text,
                  !isPersonCell ? highlightText : null,
                  !isPersonCell && !editMode,
                  uniqueIndex
                )}
              </Box>
            </Show>
            <Show below="sm">
              <Box>
                {renderText(
                  text ? text.replaceAll(",", "").replaceAll(" ", "") : text,
                  !isPersonCell ? highlightText : null,
                  !isPersonCell && !editMode,
                  uniqueIndex
                )}
              </Box>
            </Show>
          </>
        })}
      </Td>
    );
  }

  function renderText(text, selectedText, showAsPopout, uniqueIndex) {
    return doRenderText(
      text,
      showAsPopout,
      showAsPopout,
      selectedText,
      uniqueIndex
    );
  }

  function doRenderText(
    text,
    showAsPopout,
    clickable,
    highlightedText,
    uniqueIndex = new Date()
  ) {
    if (uniqueIndex === popoverSectionID && showAsPopout) {
      return renderWordInfoPopover(text, highlightedText, uniqueIndex);
    } else {
      let style = { whiteSpace: 'pre-line' };
      if (clickable) {
        style = { cursor: "pointer" };
      }

      if (text && highlightedText) {
        const regex = accentInsensitiveRegex(highlightedText);
        const parts = text.split(regex);
        const highlightStyle = {
          backgroundColor: selectedTextBackgroundColor,
          color: "black",
          display: "inline", // Ensure the highlight is inline
        };

        if (parts.length === 1 && !regex.test(parts[0])) {
          return renderTextElement(text, style);
        }

        return (
          <Box flexGrow={1} style={style} data-test={`text_wrapper_${text}`}>
            {parts.filter(Boolean).map((part, i) => {
              // Check if part is not an empty string and matches the regex
              return regex.test(part) ? (
                <Text key={i} style={highlightStyle}>
                  {part}
                </Text>
              ) : (
                <Text color="lightGrey" display="inline" key={i}>
                  {part}
                </Text>
              );
            })}
          </Box>
        );
      }
      return renderTextElement(text, style);
    }
  }

  function renderTextElement(text, style) {
    return (
      <Text
        style={style}
        color="lightGrey"
        width={"fit-content"}
        margin={"auto"}
        whiteSpace="pre-wrap" // Allow text to render as multiline
        data-test={`text_element_${text}`}
      >
        {text}
      </Text>
    );
  }

  function renderTableHeaderCell(row, rowIndex, cell, cellIndex, cellCount) {
    return (
      <Td
        border={tableBorder}
        borderColor="white"
        // width="25%"
        w={`${100/(cellCount ?? 1)}%`} 
        // p={2}
        key={cellIndex}
        colSpan={cell.colSpan || 1}
        textAlign="center"
        fontWeight="bold"
        data-test={`header_cell_${cell.text}`}
        className={
          text(cell, "text") === "singular" || text(cell, "text") === "plural"
            ? "td1"
            : ""
        }
      >
        <Text color="white" width={"fit-content"} margin={"auto"}>
          {text(cell, "text")}
        </Text>
      </Td>
    );
  }

  function text(section, key) {
    if (section.localize) {
      let text = section[key].trim().toLowerCase();

      let localizeKey = null
      function isValidDotSeparatedString(str) {
        const regex = /^[a-zA-Z0-9_]+(\.[a-zA-Z0-9_]+)+$/;
        return regex.test(str);
      }

      if (isValidDotSeparatedString(text)) {
        localizeKey = text;
      } else {
        localizeKey = `lang.${text}`;
      }

      let localized = t(localizeKey);

      if (localized !== localizeKey) {
        return localized;
      } else {
        return section[key];
      }
    } else if (section.count && section.index) {
      return localizePerson(section.count, section.index);
    } else {
      return section[key];
    }
  }

  function localizePerson(count, index) {
    let prefix = "st";
    switch (index) {
      case 1:
        prefix = "st";
        break;
      case 2:
        prefix = "nd";
        break;
      case 3:
        prefix = "rd";
        break;
      default:
        prefix = "th";
        break;
    }

    return t(`lang.${index}${prefix}-person ${count}`);
  }

  function setMaxColumnWidth() {
    return;
    if (!sectionIDs || widthsCalculated) {
      // console.log("aboring because no section IDs or widths calculated")
      return;
    }

    // console.log('Calculating widths... ' + widthsCalculated)

    try {
      var maxFirstColumnWidth1 = 0;
      var maxFirstColumnWidth2 = 0;

      let cached = cellSizeCache[languageCode];
      // if(cached && (cached.maxFirstColumnWidth1 ?? 0) > 0 && (cached.maxFirstColumnWidth2 ?? 0) > 0) {
      //   maxFirstColumnWidth1 = cached.maxFirstColumnWidth1
      //   maxFirstColumnWidth2 = cached.maxFirstColumnWidth2
      // } else {

      const rows = document.querySelectorAll(
        `.chakra-table .chakra-collapse tr`
      );

      // let sizes = getWidthsFor('tbl')
      // var maxFirstColumnWidth1 = sizes.maxFirstColumnWidth1;
      // var maxFirstColumnWidth2 = sizes.maxFirstColumnWidth2;

      // calculate sizes

      let foundPattern = false
      rows.forEach((row) => {
        const columnCount = row.children.length;

        const firstColumn = row.querySelector("td:first-child p");
        const secondColumn = row.querySelector("td:nth-child(2) p");
        const thirdColumn = row.querySelector("td:nth-child(3) p");

        if (firstColumn && secondColumn && columnCount === 2) {
          firstColumn.classList.add("halfWidth");
          secondColumn.classList.add("halfWidth");
          foundPattern = true
        }

        if (firstColumn) {
          const width = firstColumn.offsetWidth + 16;
          maxFirstColumnWidth1 = Math.max(maxFirstColumnWidth1, width);
        }
        if (secondColumn) {
          if (columnCount === 3) {
            secondColumn.classList.add("width3");
            foundPattern = true
          }
        }
        if (thirdColumn) {
          const width = thirdColumn.offsetWidth + 16;
          maxFirstColumnWidth2 = Math.max(maxFirstColumnWidth2, width);
          if (columnCount === 3) {
            thirdColumn.classList.add("width3");
            foundPattern = true
          }
        }
      });
      
      // end calculate sizes

      let maxWidths = []
      if (!foundPattern) {
        rows.forEach((row) => {
          const columnCount = row.children.length;
          let maxColumns = 12
          for(let i = 0; i < columnCount; i++) {
            const column = row.querySelector(`td:nth-child(${i + 1}) p`);
            if (column) {
              const width = column.offsetWidth 
              maxWidths[i] = Math.max(maxWidths[i] ?? 0, width);
            }
          }
        })

        // set widths for each column

        rows.forEach((row) => {
          const columnCount = row.children.length;
          for(let i = 0; i < columnCount; i++) {
            const column = row.querySelector(`td:nth-child(${i + 1}) p`);
            if (column) {
              column.style.width = `${maxWidths[i]}px`
            }
          }
        })
      } else {
        const firstColumns = document.querySelectorAll(
          `.chakra-collapse .tr tr td:nth-child(1)`
        );
        const secondColumns = document.querySelectorAll(
          `.chakra-collapse .tr tr td:nth-child(2)`
        );
        const thirdColumns = document.querySelectorAll(
          `.chakra-collapse .tr tr td:nth-child(3)`
        );
        const lastColumns = document.querySelectorAll(
          `.chakra-collapse .tr tr td:nth-child(4)`
        );

        firstColumns.forEach((cell) => {
          const pElement = cell.querySelector("p");
          if (pElement && pElement.classList.contains("halfWidth")) {
            cell.style.width = `calc(50%)`;
          } else {
            cell.style.width = `${maxFirstColumnWidth1}px`;
          }
        });
        secondColumns.forEach((cell) => {
          const pElement = cell.querySelector("p");
          if (pElement && pElement.classList.contains("halfWidth")) {
            cell.style.width = `calc(50%)`;
          } else if (pElement && pElement.classList.contains("width3")) {
            cell.style.width = `calc(50% - ${maxFirstColumnWidth1 / 2}px)`;
          } else {
            cell.style.width = `calc(50% - ${maxFirstColumnWidth1 + 10}px)`;
            cell.style.marginRight = `10px`;
            cell.style.borderRight = `0.5px solid #555`;
          }
        });
        thirdColumns.forEach((cell) => {
          const pElement = cell.querySelector("p");
          if (pElement && pElement.classList.contains("width3")) {
            cell.style.width = `calc(50% - ${maxFirstColumnWidth1 / 2}px)`;
          } else {
            cell.style.width = `${maxFirstColumnWidth2}px`;
          }
        });
        lastColumns.forEach((cell) => {
          cell.style.width = `calc(50% - ${maxFirstColumnWidth2}px)`;
        });

      }

      // set width calculated to true after a 1 second timeout; invalidate last width calculation

      if (maxFirstColumnWidth1 > 0 && maxFirstColumnWidth2 > 0) {
        setWidthsCalculated(true);
        // console.log(`setting widths calculated!`)
      } 
      setTimeout(() => {
        if (
          maxFirstColumnWidth1 > 0 &&
          maxFirstColumnWidth2 > 0 &&
          !cellSizeCache[languageCode]
        ) {
          setCellSizeCache({
            ...cellSizeCache,
            [languageCode]: { maxFirstColumnWidth1, maxFirstColumnWidth2 },
          });
        }
      }, 2000);
    } catch (e) {
      return;
    }
  }

  function changeOpacity() {
    document.querySelectorAll(".chakra-table tr").forEach((row) => {
      row.style.opacity = "1";
    });
  }

  const handleOpenPopover = (text) => {
    setDoubleSelectedText(text);
    if (doubleSelectedText === text) {
      setSelectedPopover(null);
      setDoubleSelectedText(null);
    } else {
      setSelectedPopover(text);
    }
  };

  const handleClosePopover = () => {
    setSelectedPopover(null);
    setPopoverSectionID(null);
  };

  const renderWordInfoPopover = (text, highlightedText, uniqueIndex) => {
    return (
      <>
        <Popover
          data-test={`word_info_popover_${text}`}
          key={text}
          ref={popoverRef}
          isOpen={popoverSectionID === uniqueIndex}
          onOpen={() => handleOpenPopover(text + "_" + uniqueIndex)}
          onClose={handleClosePopover}
          opacity={isPopoverOpen(text + "_" + uniqueIndex) ? 1 : 0}
        >
          <PopoverTrigger data-test={`word_info_trigger_${text}`}>
            {doRenderText(text, false, true, highlightedText, null)}
          </PopoverTrigger>
          <PopoverContent
            data-test={`word_info_content_${text}`}
            bgImage="url('/icons/dialog.png')"
            bgSize="cover"
            bgRepeat="no-repeat"
            borderColor="dialogBorder"
          >
            <PopoverBody color="white">
              <WordInfoDialog
                data-test={`word-info-dialog_${text}`}
                onClose={() => {
                  handleClosePopover();
                }}
                popoverRef={popoverRef}
                term={text}
                termLanguageCode={languageCode}
                userLanguageCode={userLanguageCode}
              />
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </>
    );
  };

  return (
    <Box id={id}>
      {cellModel &&
        (!showSnippetTitle ? (
          <Box h="0px"></Box>
        ) : (
          <Box overflowY="auto" borderRadius="md">

            {showSnippetTitle && 
              !hideHeader &&
              renderSnippetTitle2(cellModel, canPinSnippet)}

            {renderSnippets()}
            {!widthsCalculated && sectionIDs && setMaxColumnWidth()}
          </Box>
        ))}
    </Box>
  );
}

export default SnippetTable;

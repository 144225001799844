import { Box, Image, Container, Flex, Text, useMediaQuery, IconButton, Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, Avatar, Button, DrawerBody, Divider, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import ChooseYourLanguage from "../choose-your-language";
import { useEffect, useState } from "react";
import { Constants } from "../../utils/constants";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../api/firebaseConfig";
import { TiThMenu } from "react-icons/ti";
import LoginRegisterPopover from "../login-register";
import { useUser } from "../../auth/user-context";
import { ArrowBackIcon, SettingsIcon } from "@chakra-ui/icons";

const LandingNavBar = () => {

    const [isMobile] = useMediaQuery(Constants.MEDIA_QUERY_MOBILE);    

    const [links, setLinks] = useState([]);
    // const [user] = useAuthState(auth);
    const {
        user,
        userLanguage,
        setUserLanguage,
        authenticationFinished,
        isInitiallyLoggedIn,
    } = useUser()

    const { t } = useTranslation();

    const [showSideMenu, setShowSideMenu] = useState(false);
    const onClose = () => setShowSideMenu(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            setLinks(Constants.Header.authedLinks());
        } else {
            setLinks(Constants.Header.nonAuthedLinks);
        }

    }, [user]);

    async function handleLogout() {
        await auth.signOut();
        navigate('/')
    }

    const maxWidth = isMobile ? '100%' : '80%';

    return (<Container maxW={maxWidth} position="relative" pt="5" pb="0" color="white" zIndex={Constants.zIndex.header}>
        
            <Flex align="center" justify="space-between">
                {/* Logo on the far left */}
                <Box>
                    <Box display="flex" flexDirection="row" alignItems='center' gap={4}>
                        <Image style={{ userSelect: "none" }} src="/icons/landing/logo.png" alt="Learner App Logo" height="44px" />

                        {!isMobile && links.map((link) => (
                            <RouterLink to={link.url} >
                                <Text color="white" px={2} pt={2}>{t(link.key)}</Text>
                            </RouterLink>
                        ))}
                    </Box>
                </Box>
                <Flex alignItems='center' >
                    <ChooseYourLanguage mt={2} title={isMobile ? '' : null} zIndex={20} />
                    {isMobile && <IconButton
                        data-test="menu-button"
                        mr={0}
                        ml={2}
                        mt={2}
                        colorScheme="none"
                        variant='none'
                        aria-label="Open menu"
                        icon={<TiThMenu size="36px" color="white" />}
                        onClick={() => setShowSideMenu(true)}
                    />}

                    {!isMobile && authenticationFinished && <>
                        {!user && <LoginRegisterPopover
                            triggerRef={
                                <Button ml={2} mt={2} data-test='login-register-button' colorScheme="green" pl={5} pr={5}>
                                    {t("header.sign_in")}
                                </Button>
                            }
                        />
                        }

                        {user && <Menu>
                            <MenuButton
                                pt={2}
                                ml={2}
                                colorScheme=""
                                as={IconButton}
                                aria-label="Options"
                                icon={<Avatar width="32px" height="32px" />}
                                variant="solid"
                            />
                            <MenuList backgroundColor="darkPurple" zIndex={Constants.zIndex.popover}>
                                <MenuItem
                                    data-test="user-profile-button"
                                    backgroundColor="#00000000"
                                    color="white"
                                    icon={<SettingsIcon />}
                                    onClick={() => {
                                        window.location.href = "/profile";
                                    }}
                                    _hover={{ backgroundColor: "purple" }} // Change 'hoverColor' to your desired color
                                >
                                    {t("header.user_profile")}
                                </MenuItem>
                                <MenuItem
                                    data-test="logout-button"
                                    backgroundColor="#00000000"
                                    color="red"
                                    icon={<ArrowBackIcon />}
                                    onClick={handleLogout}
                                    _hover={{ backgroundColor: "purple" }} // Change 'hoverColor' to your desired color
                                >
                                    {t("header.logout")}
                                </MenuItem>
                            </MenuList>
                        </Menu>
                        }

                    </>
                    }
                </Flex>
            </Flex>

        <Drawer isOpen={showSideMenu} onClose={onClose} placement="left">
            <DrawerOverlay />
            <DrawerContent backgroundColor="darkPurple">
                <DrawerCloseButton color="white" />
                <DrawerHeader>
                    {user && (
                        <Box>
                            <Flex direction="column" alignItems="center">
                                <Avatar width="128px" height="128px" />
                                <Text color="white">{user.displayName || ""}</Text>
                            </Flex>
                        </Box>
                    )}

                    {!user && (
                        <Box align="center" width="100%">
                            <Flex direction="column" alignItems="center">
                                <LoginRegisterPopover
                                    triggerRef={
                                        <IconButton
                                            data-test="login-register-button"
                                            variant="unstyled"
                                            width="128px"
                                            height="128px"
                                            aspectRatio={1}
                                            icon={<Avatar width="100%" height="100%" />}
                                        />
                                    }
                                />
                                <LoginRegisterPopover
                                    triggerRef={
                                        <Button variant="unstyled" color="white">
                                            {t("header.sign_in")}
                                        </Button>
                                    }
                                />
                            </Flex>
                        </Box>
                    )}
                </DrawerHeader>
                <DrawerBody>
                    <Flex direction="column">
                        {links.map((link) => (
                            <Box>
                                <Button
                                    fontWeight="bold"
                                    w="100%"
                                    justifyContent="flex-start"
                                    variant="unstyled"
                                    textAlign="start"
                                    color="white"
                                    onClick={() => {
                                        navigate(link.url);
                                    }}
                                >
                                    {t(link.key)}
                                </Button>
                                <Divider />
                            </Box>
                        ))}
                        {user && (
                            <Button
                                data-test="logout-button"
                                w="100%"
                                justifyContent="flex-start"
                                variant="ghost"
                                color="red"
                                onClick={() => {
                                    // handleLogout();
                                }}
                            >
                                {t("header.logout")}
                            </Button>
                        )}
                    </Flex>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    </Container>);
};

export default LandingNavBar;

import React, { useEffect, useState } from "react";
import { Box, VStack, HStack, Text, Input, Divider, Spacer } from "@chakra-ui/react";

function MappingInspector({ sentence, mappings }) {
  const [localMappings, setLocalMappings] = useState([...mappings]);

  const handleUpdateMapping = (index, updatedMapping) => {
    const updatedMappings = [...localMappings];
    updatedMappings[index] = updatedMapping;
    setLocalMappings(updatedMappings);
  };

  return (
    <HStack spacing={4} align="flex-start" width="100%" padding={4} color="white">
      {/* Editor pane */}
      <Box flex={3 / 4} borderWidth={1} borderRadius="md" padding={4}>
        <Text fontWeight="bold" fontSize="xl" marginBottom={4}>
          Mapping Editor
        </Text>
        <VStack spacing={4} align="stretch">
          {localMappings.map((mapping, index) => (
            <MappingItem
              key={index}
              sentence={sentence}
              mapping={mapping}
              onUpdate={(updatedMapping) => handleUpdateMapping(index, updatedMapping)}
              backgroundColor={index % 2 === 0 ? "darkPurple" : "purple"}
            />
          ))}
        </VStack>
      </Box>

      {/* Right Pane (Empty) */}
      <Box flex={1 / 4} borderWidth={1} borderRadius="md" padding={4}>
        <Text fontWeight="bold" fontSize="xl" marginBottom={4}>
          Right Pane
        </Text>
      </Box>
    </HStack>
  );
}

function MappingItem({ sentence, mapping, onUpdate, ...props }) {
  const [localMapping, setLocalMapping] = useState({ ...mapping });
  const [parts, setParts] = useState({ before: "", bold: "", after: "" });
  const [errors, setErrors] = useState({ length: false, location: false });

  useEffect(() => {
    if (!sentence || !localMapping) return;

    const { location, length } = localMapping.range;
    const start = parseInt(location, 10);
    const end = start + parseInt(length, 10);

    if (isNaN(start) || isNaN(end)) {
      setParts({ before: "", bold: "", after: "" });
      return;
    }

    setParts({
      before: sentence.substring(0, start),
      bold: sentence.substring(start, end),
      after: sentence.substring(end),
    });
  }, [sentence, localMapping]);

  const handleInputChange = (field, value) => {
    const updatedRange = { ...localMapping.range, [field]: value };
    const hasError = isNaN(parseInt(value, 10));

    setErrors((prevErrors) => ({ ...prevErrors, [field]: hasError }));

    const updatedMapping = { ...localMapping, range: updatedRange };
    setLocalMapping(updatedMapping);

    if (!hasError) onUpdate(updatedMapping);
  };

  return (
    <Box borderWidth={1} borderRadius="md" padding={4} {...props}>
      <VStack align="stretch" spacing={2}>
        <Text fontWeight="bold">ID: {localMapping.id}</Text>

        <Text fontWeight="bold">Sentence:</Text>
        <Text>
          {parts.before}
          <Text as="span" fontWeight="bold" backgroundColor="white" color="black">
            {parts.bold}
          </Text>
          {parts.after}
        </Text>

        <VStack>
          <HStack>
            <Text>Substring:</Text>
            <Input size="sm" value={parts.bold} readOnly />
          </HStack>

          <HStack>
            <Text>Location:</Text>
            <Input
              size="sm"
              value={localMapping.range.location}
              onChange={(e) => handleInputChange("location", e.target.value)}
              isInvalid={errors.location}
            />
          </HStack>
          <HStack>
            <Text>Length:</Text>
            <Input
              size="sm"
              value={localMapping.range.length}
              onChange={(e) => handleInputChange("length", e.target.value)}
              isInvalid={errors.length}
            />
          </HStack>
        </VStack>

        <Divider />

        <HStack>
          <Text>Text:</Text>
          <Input size="sm" value={localMapping.text} readOnly />
        </HStack>

        <Text fontWeight="bold">Snippets:</Text>
        {localMapping.snippets.map((snippet, idx) => (
          <Box key={idx} borderWidth={1} borderRadius="md" padding={2}>
            <Text>ID: {snippet.id}</Text>
            <HStack>
              <Text>Text:</Text>
              <Input size="sm" value={snippet.text} readOnly />
            </HStack>
            <HStack>
              <Text>Word Type:</Text>
              <Input size="sm" value={snippet.word_type} readOnly />
            </HStack>
            {snippet.infinitive && (
              <HStack>
                <Text>Infinitive:</Text>
                <Input size="sm" value={snippet.infinitive} readOnly />
              </HStack>
            )}
            {snippet.tense && (
              <HStack>
                <Text>Tense:</Text>
                <Input size="sm" value={snippet.tense} readOnly />
              </HStack>
            )}
          </Box>
        ))}
      </VStack>
    </Box>
  );
}

export default MappingInspector;
